import React, { useState, useReducer } from 'react';
import { Validator, Input } from 'common/components';
import { Layer } from 'layout';
import produce from 'immer';
import classNames from 'classnames';
import { debounce } from 'lodash';
import { findCustomer, api, process } from 'common/functions';
import { NullishGuard } from 'hoc';
import Result from './result/Result';
import './FindPw.scss';

export type Action =
  | { type: 'loading'; payload: boolean }
  | { type: 'customer'; payload: Partial<{ name: string; error: string; id: number }> }
  | { type: 'user'; payload: Partial<{ name: string; username: string; email: string }> }
  | { type: 'result'; payload: { email: string; done: boolean } }
  | { type: 'success'; payload: boolean };

export interface State {
  loading: boolean;
  type: '아이디 찾기' | '비밀번호 찾기';
  customer: { name: string; error: string; id: number };
  user: { name: string; username: string; email: string };
  result: { email: string; done: boolean };
  success: boolean;
}

const initialState: State = {
  loading: false,
  type: '아이디 찾기',
  customer: { name: '', error: '', id: -2 },
  user: { name: '', username: '', email: '' },
  result: { email: '', done: false },
  success: true,
};

const reducer = (state: State, action: Action) => {
  const { type, payload } = action;
  // prettier-ignore
  return produce(state, (draft) => {
    switch (type) {
      case 'loading': draft['loading'] = payload; break;
      case 'customer': draft['customer'] = { ...state.customer, ...payload }; break;
      case 'user': draft['user'] = { ...state.user, ...payload }; break;
      case 'result': draft['result'] = payload; break;
      case 'success': draft['success'] = payload; break;
    }
  });
};

interface Props {
  children: React.ReactNode;
  set: (payload: { success?: string | JSX.Element; fail?: string }) => void;
}

const FindPw: React.FC<Props> = ({ children, set }) => {
  const [state, setState] = useReducer(reducer, initialState);
  const { loading, customer, user, result } = state;
  const timer = () => {
    const info = async (name: string) => {
      const result = await findCustomer({ customer_name: name });
      if (result.id === -1) result.error = '등록되지 않은 고객사명입니다.';
      setState({ type: 'customer', payload: result });
    };
    return debounce(info, 500);
  };

  const [customerCheck] = useState(timer);

  const submitHandler = async () => {
    // 고객사가 존재할때
    if (customer.id > -1) {
      setState({ type: 'loading', payload: true });
      const params = {
        customer_id: customer.id,
        username: user.username,
        name: user.name,
        email: user.email,
      };
      if (state.result.done) {
        set({ success: '재발송이 완료되었습니다.' });
      }

      process(
        api.reqData({ url: 'find/password', data: params }),
        api.post.request,
        api.fullFilled(({ response }) => {
          const payload = { email: '', done: true };
          setState({ type: 'result', payload });
          setState({ type: 'loading', payload: false });

          if (response) payload.email = user.email;

          if (response?.data.api_response.result_code === 'N100') setState({ type: 'success', payload: true });
          else setState({ type: 'success', payload: false });
        })
      );
    }
  };

  return (
    <>
      <NullishGuard data={[result.done]}>
        <Result state={state} setState={setState} submitHandler={submitHandler} loading={loading} />
      </NullishGuard>

      <NullishGuard data={[!result.done]}>
        <Validator.Provider id="kyc-find-pw-form-field" form onSubmit={submitHandler}>
          {children}
          <Layer styles={{ marginBottom: 16 }}>
            <Input
              className={classNames('basic', {
                error: customer.error,
                success: !customer.error && customer.id > -2,
              })}
              placeholder="고객사명을 입력하세요."
              validType="realtime"
              rules={['required']}
              onChange={(e) => {
                const name = e.target.value;
                setState({ type: 'customer', payload: { name, error: '', id: -2 } });
                if (name) {
                  customerCheck.cancel();
                  customerCheck(name);
                }
                if (!name.length) customerCheck.cancel();
              }}
            />
            {customer.error && (
              <div className={classNames('api-check-message', { error: customer.error })}>{customer.error}</div>
            )}
          </Layer>

          <Layer styles={{ marginBottom: 16 }}>
            <Input
              className="basic"
              placeholder="아이디"
              validType="realtime"
              rules={['id']}
              onChange={(e) => setState({ type: 'user', payload: { username: e.target.value } })}
            />
          </Layer>

          <Layer styles={{ marginBottom: 16 }}>
            <Input
              className="basic"
              placeholder="이름"
              validType="realtime"
              rules={['name', { maxLength: 100 }]}
              onChange={(e) => setState({ type: 'user', payload: { name: e.target.value } })}
            />
          </Layer>

          <Layer styles={{ marginBottom: 16 }}>
            <Input
              className="basic"
              placeholder="이메일"
              validType="realtime"
              rules={['email', { minLength: 6, maxLength: 250 }]}
              onChange={(e) => setState({ type: 'user', payload: { email: e.target.value } })}
            />
          </Layer>

          <Validator.Submit
            text="다음"
            disabled={state.customer.id < 0 || !state.user.name || !state.user.username || !state.user.email}
            loading={loading}
            styles={{ width: '100%', height: 56, radius: 8 }}
          />
        </Validator.Provider>
      </NullishGuard>
    </>
  );
};

export default FindPw;
