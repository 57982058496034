import React, { useReducer, Dispatch } from 'react';
import { useParams } from 'react-router-dom';
import { Validator, Input, Button } from 'common/components';
import { modal, Layer } from 'layout';
import produce from 'immer';
import classNames from 'classnames';
import { api, process, checkEmail } from 'common/functions';
import type { State as CtState, Action as CtAction } from '../SignUpPage';
import './Information.scss';

type Action =
  | { type: 'loading'; payload: boolean }
  | { type: 'data'; payload: Partial<{ name: string; phone_number: string }> }
  | { type: 'email'; payload: Partial<{ value: string; message: string; check: boolean }> }
  | { type: 'username'; payload: Partial<{ value: string; message: string; check: boolean }> };

interface State {
  loading: boolean;
  data: { name: string; phone_number: string };
  email: { value: string; message: string; check: boolean };
  username: { value: string; message: string; check: boolean };
}

const initialState: State = {
  loading: false,
  data: { name: '', phone_number: '' },
  email: { value: '', message: '', check: false },
  username: { value: '', message: '', check: false },
};

const reducer = (state: State, action: Action) => {
  // prettier-ignore
  return produce(state, (draft) => {
    switch (action.type) {
      case 'loading': draft['loading'] = action.payload; break;
      case 'data': draft['data'] = { ...draft.data, ...action.payload }; break;
      case 'email': draft['email'] = { ...draft.email, ...action.payload }; break;
      case 'username': draft['username'] = { ...draft.username, ...action.payload }; break;
    }
  });
};

interface Props {
  state: CtState;
  setState: Dispatch<CtAction>;
}

const Information: React.FC<Props> = ({ state: CtState, setState: setCtState }) => {
  const { role } = useParams();
  const [state, setState] = useReducer(reducer, initialState);
  const { customer_id } = CtState;
  const styles = { marginTop: 8, marginBottom: 20 };

  // 아이디 중복 확인
  const idCheckHandler = () => {
    const params = { customer_id, value: state.username.value };
    process(
      api.reqData({ url: 'check/id', data: params }),
      api.post.request,
      api.fullFilled(({ response, error }) => {
        let payload = { message: '', check: false };
        if (response) {
          const { result_code } = response.data.api_response;
          if (result_code === 'E600') payload = { message: '입력 유형이 올바르지 않습니다. 확인 후 수정해 주세요.', check: false }; // prettier-ignore
          if (result_code === 'F602') payload = { message: '이미 사용중인 아이디입니다.', check: false };
          if (result_code === 'N100') payload = { message: '사용 가능한 아이디입니다.', check: true };
        }

        if (error) {
          const { result_code } = error.data.api_response;
          if (result_code === 'E600') payload = { message: '8~20자의 영문 대, 소문자만 사용 가능합니다.', check: false }; // prettier-ignore
          if (result_code === 'E531') payload = { message: '아이디를 입력해 주세요.', check: false }; // prettier-ignore
        }

        setState({ type: 'username', payload });
      })
    );
  };

  // 이메일 중복 확인
  const emailCheckHandler = async () => {
    if (customer_id) {
      const result = await checkEmail({ customer_id, value: state.email.value });
      setState({ type: 'email', payload: result });
    }
  };

  const submitHandler = async () => {
    setState({ type: 'loading', payload: true });
    const params = { customer_id, ...state.data, email: state.email.value, username: state.username.value };
    const url = role === 'admin' ? `customers/${customer_id}/admins` : 'reviewers';
    process(
      api.reqData({ url, data: params }),
      api.post.request,
      api.fullFilled(({ response }) => {
        if (response) {
          const { result_code } = response.data;
          if (result_code === 'N100') setCtState({ type: 'step', payload: 4 });
        }
      })
    );
  };

  return (
    <Validator.Provider className="information" onSubmit={submitHandler}>
      <modal.Heading>아이디</modal.Heading>
      <Validator.Provider onSubmit={idCheckHandler}>
        <div className="duplicate-check">
          <Input
            className={classNames('basic', { error: state.username.message && !state.username.check })}
            placeholder="8~20자의 영문, 숫자로 입력하세요."
            validType="realtime"
            rules={['id']}
            value={state.username.value}
            onChange={(e, error) => {
              setState({
                type: 'username',
                payload: {
                  value: e.target.value,
                  message: !error && e.target.value.length !== 0 ? '중복 여부를 확인하세요.' : '',
                  check: false,
                },
              });
            }}
          />
          <Validator.Submit text="중복확인" />
          {state.username.message && (
            <div className={classNames('api-check-message', { error: !state.username.check })}>
              {state.username.message}
            </div>
          )}
        </div>
      </Validator.Provider>

      <modal.Heading styles={{ fontSize: 16 }}>이름</modal.Heading>
      <Layer styles={styles}>
        <Input
          className="basic"
          validType="realtime"
          rules={['required', 'name', { maxLength: 80 }]}
          placeholder="이름을 입력하세요."
          value={state.data.name}
          onChange={(e) => {
            setState({ type: 'data', payload: { name: e.target.value } });
          }}
        />
      </Layer>

      <modal.Heading styles={{ fontSize: 16 }}>전화번호</modal.Heading>
      <Layer styles={styles}>
        <Input
          className="basic"
          validType="realtime"
          rules={['required', 'phone']}
          placeholder="전화번호를 입력하세요."
          value={state.data.phone_number}
          onChange={(e) => {
            setState({ type: 'data', payload: { phone_number: e.target.value } });
          }}
        />
      </Layer>

      <modal.Heading styles={{ fontSize: 16 }}>이메일</modal.Heading>
      <Validator.Provider onSubmit={emailCheckHandler}>
        <div className="duplicate-check">
          <Input
            className={classNames('basic', { error: state.email.message && !state.email.check })}
            placeholder="이메일을 입력하세요."
            value={state.email.value}
            validType="realtime"
            rules={['email', { minLength: 6, maxLength: 250 }]}
            onChange={(e, error) => {
              setState({
                type: 'email',
                payload: {
                  value: e.target.value,
                  message: !error && e.target.value.length !== 0 ? '중복 여부를 확인하세요.' : '',
                  check: false,
                },
              });
            }}
          />
          <Validator.Submit text="중복확인" />
          {state.email.message && (
            <div className={classNames('api-check-message', { error: !state.email.check })}>{state.email.message}</div>
          )}
        </div>
      </Validator.Provider>

      <div className="next-field">
        <Button text="뒤로" onClick={() => setCtState({ type: 'step', payload: 2 })} />
        <Validator.Submit loading={state.loading} text="등록" disabled={!state.username.check || !state.email.check} />
      </div>
    </Validator.Provider>
  );
};

export default Information;
