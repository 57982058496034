import React, { useContext, useEffect, useReducer } from 'react';
import { produce } from 'immer';
// import { api, process } from 'common/functions';
import { ContentContext } from 'layout/admin/content/Content';
import { EddCategory, NationRisk, Risk, eddCategoryFactory, eddNationFactory } from '../data/data';
import RaWeightProvider, { useRaWeightContext } from '../context/WeightContext';
import RealScoreProvider from '../context/RealScoreContext';
import RaType from './ratype/RaType';
import './RealScoreContent.scss';

export type State = {
  eddCategory: EddCategory[];
  eddField: Risk[];
  nation: NationRisk[];
};

export type Action =
  | { type: 'eddCategory'; payload: EddCategory[] }
  | { type: 'eddField'; payload: Risk[] }
  | { type: 'nation'; payload: NationRisk[] };

const initialState: State = {
  eddCategory: [],
  eddField: [],
  nation: [],
};

const reducer = (state: State, action: Action) => {
  return produce(state, (draft) => {
    // prettier-ignore
    switch (action.type) {
      case 'eddCategory': draft['eddCategory'] = action.payload; break;
      case 'eddField': draft['eddField'] = action.payload; break;
      case 'nation': draft['nation'] = action.payload; break;
      default: break;
    }
  });
};
// TODO: API int
// const get = (url: string) => {
//   return process(
//     api.reqData({ url }),
//     api.get.request,
//     api.fullFilled(({ response }) => {
//       if (response?.data.list) return response?.data.list;
//       return undefined;
//     })
//   );
// };

// const put = (url: string, data: { use_ra: string } | { customer_id: number; module: { custom_field: boolean } }) => {
//   return process(api.reqData({ url, data }), api.put.request);
// };

const [getCateData] = eddCategoryFactory();
const [getNationData] = eddNationFactory();

function RealScoreContent() {
  const { setLoading } = useContext(ContentContext);
  // prettier-ignore
  const { state: { data: raWeight } } = useRaWeightContext();
  const [state, setState] = useReducer(reducer, initialState);

  const loadHandler = async () => {
    setLoading(true);

    const [cate, nation] = await Promise.all([getCateData(), getNationData()]);

    setState({ type: 'eddCategory', payload: cate });
    setState({ type: 'nation', payload: nation });

    setLoading(false);
  };

  useEffect(() => {
    loadHandler();
  }, []);

  console.log({ state });

  return (
    <div id="admin-manage-ra-realscore-content">
      <RaWeightProvider>
        {raWeight.map((raItem) => (
          <RealScoreProvider key={raItem.id}>
            <RaType key={raItem.id} raItem={raItem} />
          </RealScoreProvider>
        ))}
      </RaWeightProvider>
    </div>
  );
}

export default RealScoreContent;
