import React, { useContext, useEffect, useReducer } from 'react';
import produce from 'immer';
import classNames from 'classnames';
import styled from 'styled-components';
import { Alert, Button } from 'common/components';
import { ContentContext } from 'layout/admin/content/Content';
import { useEddConext } from '../../../context/EddContext';
import { eddNationFactory } from '../../../data/data';
import NationRiskItem from './NationRiskItem';
import './NationRisk.scss';

const RiskContent = styled.div<{ expand: boolean }>`
  transition: max-height 0.3s ease, padding 0.3s ease;
  border-radius: 8px;
  ${({ expand }) => {
    if (expand) return `overflow: auto; max-height: 870px; padding: 8px 16px;`;
    return `overflow: hidden; max-height: 0; padding: 0 16px;`;
  }}
}}
`;

type Action =
  | { type: 'edit'; payload: boolean }
  | { type: 'expand'; payload: boolean }
  | { type: 'data'; payload: Data[] }
  | { type: 'defaultData'; payload: Data[] };

export type Data = {
  country_id: number;
  score: number;
  iso_code2: string;
  iso_code3: string;
  name_en: string;
  name_kr: string;
  warn_labels: string[];
};
type State = {
  edit: boolean;
  expand: boolean;
  data: Data[];
  defaultData: Data[];
};

const reducer = (state: State, action: Action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case 'edit':
        draft['edit'] = action.payload;
        break;
      case 'expand':
        draft['expand'] = action.payload;
        break;
      case 'data':
        draft['data'] = action.payload;
        break;
      case 'defaultData':
        draft['defaultData'] = action.payload;
        break;
      default:
        break;
    }
  });
};

const initialState = {
  edit: false,
  expand: false,
  data: [],
  defaultData: [],
};

const [getData, setData] = eddNationFactory();

interface Props {
  use_ra: boolean;
}
function NationRisk(props: Props) {
  const { use_ra } = props;
  const { setLoading } = useContext(ContentContext);
  const { set } = Alert.Context();

  const { edit } = useEddConext();
  const { toggleEdit } = edit;
  const riskEditable = !edit.config && !edit.risk;

  const [state, setState] = useReducer(reducer, initialState);

  const loadHandler = () => {
    setLoading(true);
    // TODO: 위험 GET
    const newState = getData();
    setState({ type: 'data', payload: newState });
    setState({ type: 'defaultData', payload: newState });

    setLoading(false);
  };

  useEffect(() => {
    loadHandler();
  }, []);

  const submitHandler = () => {
    setLoading(true);

    // TODO: 위험 PUT, POST
    setData(state.data.map((el, i) => ({ ...el, order: i + 1 })));
    loadHandler();

    console.log('국가 위험 submitHandler', { data: state.data });
    setState({ type: 'edit', payload: false });
    toggleEdit('risk');
    setLoading(false);
    set({ success: '위험 설정이 적용되었습니다.' });
  };

  const updateRaItem = (nextData: Data[]) => {
    setState({ type: 'data', payload: nextData });
  };

  // const disabled = use_ra;

  return (
    <article className="edd-setting-nation-risk">
      <div className={classNames('title', { expand: state.expand })}>
        <i
          className={classNames({ expand: state.expand })}
          onClick={() => {
            setState({ type: 'expand', payload: !state.expand });
          }}
        />
        <h4>국가 위험</h4>
        <div className="score">
          {use_ra && (
            <>
              <div>
                <span />
                <span>100점</span>
              </div>
              <div />
            </>
          )}
        </div>

        <div className={classNames('control', { expand: state.expand })}>
          <div className="button-group">
            {state.edit ? (
              <>
                <Button
                  text="취소"
                  onClick={() => {
                    setState({ type: 'data', payload: state.defaultData });
                    setState({ type: 'edit', payload: false });
                    toggleEdit('risk');
                  }}
                />
                <Button text="저장" onClick={submitHandler} />
              </>
            ) : (
              riskEditable && (
                <Button
                  text="위험 편집"
                  onClick={() => {
                    setState({ type: 'edit', payload: true });
                    toggleEdit('risk');
                  }}
                />
              )
            )}
          </div>
        </div>
      </div>
      <RiskContent expand={state.expand}>
        <div className="nation-risk-type">자동 갱신 / 직접 설정</div>
        <div className="item-container">
          <div className="item-list">
            <NationRiskItem use_ra={use_ra} item={state.data} edit={state.edit} updateRaItem={updateRaItem} />
          </div>
        </div>
      </RiskContent>
    </article>
  );
}

export default NationRisk;
