import React from 'react';
import { useDispatch } from 'common/hooks/root';
import { authActions } from 'store/actions/root';
import './Popover_MyBox.scss';

const PopoverMyBox = () => {
  const dispatch = useDispatch();

  return (
    <div id="root-mybox-popover-content">
      <div className="top-box">
        <p className="name">루트</p>
        <hr />
        <p className="company-name">UseB</p>
      </div>

      <div className="bottom-box">
        <button type="button" className="log-out" onClick={() => dispatch(authActions.logout())}>
          로그아웃
        </button>
      </div>
    </div>
  );
};

export default PopoverMyBox;
