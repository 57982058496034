import React, { Dispatch } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from 'common/components';
import type { Action, State } from '../FindPw';
import './Result.scss';

interface Props {
  state: State;
  setState: Dispatch<Action>;
  submitHandler: () => void;
  loading: boolean;
}

const Result: React.FC<Props> = ({ state, setState, submitHandler, loading }) => {
  const { role } = useParams();
  const navigate = useNavigate();
  const { result, success } = state;
  const handlers = {
    goback: () => navigate(`/${role}`),
    retry: () => {
      setState({ type: 'result', payload: { email: '', done: false } });
      setState({ type: 'customer', payload: { id: -2 } });
    },
  };

  const template = {
    success: (
      <div className="success">
        <div className="description">
          <p>비밀번호 재설정 메일을 발송하였습니다.</p>
          <p>아래 이메일 계정의 메일함을 확인해 주세요.</p>
        </div>

        <div className="notice-email">{result.email}</div>

        <div className="extra-info">
          <i />
          <div className="text">
            <p>메일이 보이지 않는다면 스팸메일함을 확인하거나</p>
            <p>재발송 또는 고객센터로 문의해 주세요.</p>
          </div>
        </div>

        <div className="navigator">
          <Button onClick={submitHandler} text="재발송" loading={loading} />
          <Button onClick={handlers.goback} text="로그인" />
        </div>
      </div>
    ),
    fail: (
      <div className="fail">
        <div className="fail-icon">
          <i />
        </div>

        <div className="description">일치하는 정보가 없습니다.</div>

        <div className="text">
          <p>입력하신 정보로 조회된 내역이 없습니다.</p>
          <p>가입 여부를 다시 한번 확인해 주세요.</p>
        </div>

        <div className="navigator">
          <Button onClick={handlers.goback} text="이전으로" />
          <Button onClick={handlers.retry} text="재시도" />
        </div>
      </div>
    ),
  };

  return (
    <div className="kyc-find-password-result">
      <h2>비밀번호 찾기 {success ? '안내' : '결과'}</h2>
      {success ? template.success : template.fail}
    </div>
  );
};

export default Result;
