import React from 'react';
import './Promotion.scss';

function Promotion() {
  return (
    <div className="admin-manage-edd-promotion">
      <h3>추가 인증 구매하셈</h3>
      <h3>고객센터 문의 ㄱ</h3>
      <h4>cs@useb.co.kr</h4>
      <h4>070-4849-1000</h4>
    </div>
  );
}

export default Promotion;
