import React, { useContext, useEffect, useReducer } from 'react';
import { produce } from 'immer';
import { ContentContext } from 'layout/admin/content/Content';
import { Alert } from 'common/components';
// import { api, process } from 'common/functions';
import { useSelector } from 'common/hooks/admin';
import CriterionSetting from './settings/CriterionSetting';
import CriterionLogsTable from './logs/CriterionLogsTable';
import { raThresholdFactory, RaThresholdLogsType } from '../data/data';

type Data = {
  threshold: [number, number];
};
export type State = {
  edit: boolean;
  data: Data;
  defaultData: Data;
  thresholdLogs: RaThresholdLogsType[];
};

export type Action =
  | { type: 'edit'; payload: boolean }
  | { type: 'data'; payload: Data }
  | { type: 'defaultData'; payload: Data }
  | { type: 'thresholdLogs'; payload: RaThresholdLogsType[] };

const initialData: Data = {
  threshold: [30, 70],
};
const initialState: State = {
  edit: false,
  data: { ...initialData },
  defaultData: { ...initialData },
  thresholdLogs: [],
};

const reducer = (state: State, action: Action) => {
  return produce(state, (draft) => {
    // prettier-ignore
    switch (action.type) {
      case 'edit': draft['edit'] = action.payload; break;
      case 'data': draft['data'] = action.payload; break;
      case 'defaultData': draft['defaultData'] = action.payload; break;
      case 'thresholdLogs': draft['thresholdLogs'] = action.payload; break;
    }
  });
};

// TODO: API int
// const get = (url: string) => {
//   return process(
//     api.reqData({ url }),
//     api.get.request,
//     api.fullFilled(({ response }) => {
//       if (response?.data.list) return response?.data.list;
//       return undefined;
//     })
//   );
// };

const [getData, setData, getLogs] = raThresholdFactory();

function CtCriterionContent() {
  const { setLoading } = useContext(ContentContext);
  const { set } = Alert.Context();
  const customer_my = useSelector((state) => state.auth.my);

  const [state, setState] = useReducer(reducer, initialState);

  const loadHandler = () => {
    setLoading(true);

    const threshold = getData();
    const thresholdLogs = getLogs();

    setState({ type: 'data', payload: { threshold } });
    setState({ type: 'defaultData', payload: { threshold } });
    setState({ type: 'thresholdLogs', payload: thresholdLogs });

    setLoading(false);
  };

  useEffect(() => {
    loadHandler();
  }, []);

  const submitHandler = () => {
    setLoading(true);

    const info = {
      user_name: customer_my?.username ?? '',
      name: customer_my?.name ?? '',
      id: customer_my?.id ?? 0,
    };
    const data = { nextData: state.data.threshold, info };
    setData(data);
    console.log({ data: state.data });
    set({ success: '평가 기준이 적용되었습니다.' });
    loadHandler();
    setLoading(false);
  };

  // console.log({ state });

  return (
    <>
      <CriterionSetting state={state} setState={setState} submitHandler={submitHandler} />
      <CriterionLogsTable state={state} />
    </>
  );
}

export default CtCriterionContent;
