import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { Provider } from 'react-redux';
import { root } from 'store';
import { Root } from 'pages';
import * as layout from 'layout';
import MyBox from 'layout/page/header/mybox/root/Popover_MyBox';
import { Authentication, InterCepter } from 'hoc/root';

export default {
  path: '/root',
  element: (
    <Provider store={root.store}>
      <InterCepter>
        <Outlet />
      </InterCepter>
    </Provider>
  ),
  children: [
    { index: true, element: <Root.LoginPage /> } /* login page */,
    {
      path: '*',
      element: (
        <Authentication>
          <layout.Page
            myBox={MyBox}
            links={[
              { url: '/root/client-management', text: '고객사 관리' },
              { url: '/root/admin-management', text: '어드민 관리' },
              { url: '/root/terms-management', text: '약관 관리' },
              { url: '/root/terms-guidance-management', text: '약관 가이드' },
            ]}
          />
        </Authentication>
      ),
      children: [
        {
          path: 'client-management',
          element: <layout.root.Content />,
          children: [{ index: true, element: <Root.ClientPage /> }],
        },
        {
          path: 'admin-management',
          element: <layout.root.Content />,
          children: [{ index: true, element: <Root.AdminPage /> }],
        },
        {
          path: 'terms-management',
          element: <layout.root.Content />,
          children: [
            { path: 'privacy', element: <Root.Terms.PrivacyPage /> },
            { path: 'service', element: <Root.Terms.ServicePage /> },
            { path: '*', element: <Navigate to="privacy" /> },
          ],
        },
        {
          path: 'terms-guidance-management',
          element: <layout.root.Content />,
          children: [
            { path: 'privacy', element: <Root.Guidance.PrivacyPage /> },
            { path: 'service', element: <Root.Guidance.PolicyPage /> },
            { path: '*', element: <Navigate to="privacy" /> },
          ],
        },
        { path: '*', element: <Navigate to="client-management" /> },
      ],
    },
  ],
};
