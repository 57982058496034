import classNames from 'classnames';
import { Input, Switch } from 'common/components';
import React, { Dispatch } from 'react';
import type { Data, Action } from '../Expand';
import './Addons.scss';

interface Props {
  data: Data;
  edit: boolean;
  setState: Dispatch<Action>;
}

const Addons = ({ edit, data, setState }: Props) => {
  const { config } = data;
  const attrs = {
    type: 'number',
    min: '1',
    max: '100',
    styles: { width: 54, height: 22 },
    rules: [{ min: 1, max: 100 }],
  };

  return (
    <section className="root-page-client-expand-addons">
      <div className="addons">
        <h5>부가 기능 설정</h5>
        <div className="addons-container">
          <div className="addons-notification">
            <p className="extras-title">고객 신원 확인 결과</p>
            <div className="category">
              <div className="cate-title">
                이메일 발송
                <Switch
                  disabled={!edit}
                  className={classNames({ readonly: !edit })}
                  value={config.email_notification}
                  onChange={(value) => {
                    setState({ type: 'config', payload: { email_notification: !!value } });
                  }}
                />
              </div>
            </div>
            <div className="category">
              <div className="cate-title">
                SMS 발송
                <Switch
                  disabled={!edit}
                  className={classNames({ readonly: !edit })}
                  value={config.sms_notification}
                  onChange={(value) => {
                    setState({ type: 'config', payload: { sms_notification: !!value } });
                  }}
                />
              </div>
            </div>
          </div>
          <div className="addons-flex">
            <div className="addons-reuse">
              <div className="reuse-label">
                <p className="extras-title">이어하기</p>
                <Switch
                  className={classNames({ readonly: !edit })}
                  disabled={!edit}
                  value={config.allow_reuse}
                  onChange={(value) => {
                    setState({ type: 'config', payload: { allow_reuse: !!value } });
                  }}
                />
              </div>
              {config.allow_reuse && (
                <div className="category">
                  <Input
                    {...attrs}
                    className="cate-title"
                    readonly={!edit}
                    value={config.reuse_limit_minute / 1440}
                    onChange={(e) => {
                      let { value } = e.target;
                      if (Number(value) < 1) value = '1';
                      if (Number(value) > 100) value = '100';
                      setState({ type: 'config', payload: { reuse_limit_minute: Number(value) * 1440 } });
                    }}
                  />
                  일
                </div>
              )}
            </div>
            <div className="addons-theme">
              <div className="theme-label">
                <p className="extras-title">테마 설정</p>
                <Switch
                  className={classNames({ readonly: !edit })}
                  disabled={!edit}
                  value={config.allow_custom_theme}
                  onChange={(value) => {
                    setState({ type: 'config', payload: { allow_custom_theme: !!value } });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Addons;
