import React, { useContext, useEffect, useReducer } from 'react';
import produce from 'immer';
import classNames from 'classnames';
import styled from 'styled-components';
import { Button, Switch, Modal, Alert } from 'common/components';
import { ContentContext } from 'layout/admin/content/Content';
import { eddFieldFactory } from '../../../data/data';
import { useEddConext } from '../../../context/EddContext';
import { addRaData, checkActiveRaScore } from '../util';
import RiskItem from '../RiskItem';
import RiskAddItem from '../RiskAddItem';
import './ProductRisk.scss';

// TODO: EDD sample data
// const sample = [
//   {
//     id: 1,
//     name: '상품 및 서비스 종류',
//     order: 9996,
//     json_key: 'field_22',
//     active: true,

//     isEdd: true,
//     category: '상품 및 서비스 위험',
//     score: 0,
//     tree: [
//       {
//         name: '암호화페 구매 및 거래',
//         edd_value: 0,
//         tree: [
//           {
//             name: '비트코인, 이더리움 고액 대출 매매 교환',
//             edd_value: 0,
//             tree: [
//               { name: '1억원 미만', edd_value: 0, tree: [] },
//               { name: '1억원 이상', edd_value: 0, tree: [] },
//             ],
//           },
//         ],
//       },
//       {
//         name: '암호화페 보관',
//         edd_value: 0,
//         tree: [
//           {
//             name: '알트코인, 비트코인',
//             edd_value: 0,
//             tree: [
//               { name: '3개월 미만', edd_value: 0, tree: [] },
//               { name: '3개월 이상', edd_value: 0, tree: [] },
//             ],
//           },
//         ],
//       },
//     ],
//   },
// ];

const RiskContent = styled.div<{ expand: boolean }>`
  transition: max-height 0.3s ease, padding 0.3s ease;
  border-radius: 8px;
  ${({ expand }) => {
    if (expand) return `overflow: auto; max-height: 870px; padding: 8px 16px;`;
    return `overflow: hidden; max-height: 0; padding: 0 16px;`;
  }}
}}
`;

type Action =
  | { type: 'edit'; payload: boolean }
  | { type: 'expand'; payload: boolean }
  | { type: 'data'; payload: Data[] }
  | { type: 'defaultData'; payload: Data[] };

export type Data = {
  id: number;
  name: string;
  type: string;
  active: boolean;
  isAppend: boolean;
  edd_category_id: number;
  score: number;
  deletable: boolean;
  mutable: boolean;
  order: number;
  tree: { name: string; score: number; tree: Data['tree'] }[];
};
type State = {
  edit: boolean;
  expand: boolean;
  data: Data[];
  defaultData: Data[];
};

const reducer = (state: State, action: Action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case 'edit':
        draft['edit'] = action.payload;
        break;
      case 'expand':
        draft['expand'] = action.payload;
        break;
      case 'data':
        draft['data'] = action.payload;
        break;
      case 'defaultData':
        draft['defaultData'] = action.payload;
        break;
      default:
        break;
    }
  });
};

const initialState = {
  edit: false,
  expand: false,
  data: [],
  defaultData: [],
};

const [getData, setData] = eddFieldFactory();

interface Props {
  use_ra: boolean;
}
function ProductRisk({ use_ra }: Props) {
  const { setLoading } = useContext(ContentContext);
  const { set } = Alert.Context();

  const { edit } = useEddConext();
  const { toggleEdit } = edit;
  const riskEditable = !edit.config && !edit.risk;

  const [state, setState] = useReducer(reducer, initialState);

  const loadHandler = () => {
    setLoading(true);

    // TODO: 위험 GET
    const newState = getData(2).map((el) => ({ ...el, isAppend: false }));
    setState({ type: 'data', payload: newState });
    setState({ type: 'defaultData', payload: newState });

    setLoading(false);
  };

  useEffect(() => {
    loadHandler();
  }, []);

  const submitHandler = () => {
    setLoading(true);
    // TODO: 위험 PUT, POST
    setData(state.data.map((el, i) => ({ ...el, order: i + 1 })));
    loadHandler();

    console.log('상품 및 서비스 위험 submitHandler', { data: state.data });
    setState({ type: 'edit', payload: false });
    toggleEdit('risk');

    setLoading(false);
    set({ success: '위험 설정이 적용되었습니다.' });
  };

  const addRaItem = (item: Data) => {
    setState({ type: 'data', payload: [...state.data, item] });
  };

  const updateRaItem = (item: Data) => {
    const nextData = state.data.map((el) => (el.id === item.id ? item : el));
    setState({ type: 'data', payload: nextData });
  };

  const removeRaItem = (item: Data) => {
    const removed = state.data.filter((el) => el.id !== item.id);
    setState({ type: 'data', payload: removed });
  };

  const totalScore = state.data.reduce((acc, cur) => acc + cur.score, 0);
  const availableScore = 100 - totalScore;
  const satisfiedScore = checkActiveRaScore(state.data) && totalScore === 100;
  const atLeastOneActive = state.data.some((el) => el.active);

  // TODO: use_ra : true => 점수체크랑 항목 active, 선택지 변경 확인
  // use_ra : false => 항목 active, 선택지 변경 확인
  const disabled = (use_ra && !satisfiedScore) || !atLeastOneActive;

  return (
    <article className="edd-setting-product-risk">
      <div className={classNames('title', { expand: state.expand })}>
        <i
          className={classNames({ expand: state.expand })}
          onClick={() => {
            setState({ type: 'expand', payload: !state.expand });
          }}
        />
        <h4>상품 및 서비스 위험</h4>
        <div className="score">
          {use_ra && (
            <>
              <div>
                <span className={classNames({ hide: !state.edit })}>부여 점수</span>
                <span>{totalScore}점</span>
              </div>
              <div>
                {state.edit && (
                  <>
                    <span>남은 점수</span>
                    <span className={classNames({ error: availableScore !== 0 })}>{availableScore}점</span>
                  </>
                )}
              </div>
            </>
          )}
        </div>

        <div className={classNames('control', { expand: state.expand })}>
          <div className="button-group">
            {state.edit ? (
              <>
                <Button
                  text="취소"
                  onClick={() => {
                    setState({ type: 'data', payload: state.defaultData });
                    setState({ type: 'edit', payload: false });
                    toggleEdit('risk');
                  }}
                />
                <Button text="저장" disabled={disabled} onClick={submitHandler} />
              </>
            ) : (
              riskEditable && (
                <Button
                  text="위험 편집"
                  onClick={() => {
                    setState({ type: 'edit', payload: true });
                    toggleEdit('risk');
                  }}
                />
              )
            )}
          </div>
        </div>
      </div>
      <RiskContent expand={state.expand}>
        <div className="item-container">
          <div className="item-list">
            {state.data.length &&
              state.data.map((item) => {
                return (
                  <React.Fragment key={item.id}>
                    <RiskItem
                      item={item}
                      edit={state.edit}
                      active={item.active}
                      use_ra={use_ra}
                      updateRaItem={updateRaItem}
                      removeRaItem={removeRaItem}
                      activeComp={
                        <Switch
                          value={item.active}
                          onChange={(value) => {
                            updateRaItem({ ...item, active: !!value, score: !value ? 0 : item.score });
                          }}
                        />
                      }
                    />
                  </React.Fragment>
                );
              })}
          </div>
          {state.edit && (
            <Modal
              content={({ closeHandler }) => (
                <RiskAddItem item={addRaData(state.data.length, 2)} addRaItem={addRaItem} closeHandler={closeHandler} />
              )}
            >
              <Button className="item-append" type="button">
                <>
                  <i />
                  항목 추가하기
                </>
              </Button>
            </Modal>
          )}
        </div>
      </RiskContent>
    </article>
  );
}

export default ProductRisk;
