import type { CustomField } from './CustomFieldContext';

/**
 * 선택 항목(반커스텀)
 * - money_origin: 자금 원천
 * - job: 직업
 * - purpose_of_transaction: 거래 목적
 * - owner: 실 소유주
 */
export type SPECIFIED_KEY = 'money_origin' | 'job' | 'purpose_of_transaction' | 'owner' | 'address';

export const SPECIFIED_CUSTOM_KEY: { [K in SPECIFIED_KEY]: Partial<CustomField> } = {
  money_origin: {
    name: 'money_origin',
    type: 'text',
    json_key: 'money_origin',
    active: false,
    order: 9995,
    required: true,
  },
  job: {
    name: 'job',
    type: 'text',
    json_key: 'job',
    active: false,
    order: 9996,
    required: true,
  },
  purpose_of_transaction: {
    name: 'purpose_of_transaction',
    type: 'text',
    json_key: 'purpose_of_transaction',
    active: false,
    order: 9997,
    required: true,
  },
  owner: {
    name: 'owner',
    type: 'text',
    json_key: 'owner',
    active: false,
    order: 9998,
    required: true,
  },
  address: {
    name: 'address',
    type: 'text',
    json_key: 'address',
    active: false,
    order: 9999,
    required: true,
  },
};

export const initialOptionalField = {
  money_origin: { id: 999, order: 9995, required: true, active: false, isAppend: true },
  job: { id: 999, order: 9996, required: true, active: false, isAppend: true },
  purpose_of_transaction: { id: 999, order: 9997, required: true, active: false, isAppend: true },
  owner: { id: 999, order: 9998, required: true, active: false, isAppend: true },
  address: { id: 999, order: 9999, required: true, active: false, isAppend: true },
};

export function getCustomFieldParameter(customFields: CustomField[], optionalFields: typeof initialOptionalField) {
  const orderedCustomFields: Partial<CustomField>[] = customFields.map((cf, index) => ({ ...cf, order: index + 1 }));
  const postCFItems = orderedCustomFields.filter((cf) => cf.isAppend);
  const putCFItems = orderedCustomFields.filter((cf) => !cf.isAppend);

  const postOFItems = Object.entries(optionalFields)
    .filter(([, field]) => field.isAppend)
    .map(([key, field]) => ({ ...SPECIFIED_CUSTOM_KEY[key as SPECIFIED_KEY], active: field.active }));
  const putOFItems = Object.entries(optionalFields)
    .filter(([, field]) => !field.isAppend)
    .map(([key, field]) => ({ ...SPECIFIED_CUSTOM_KEY[key as SPECIFIED_KEY], id: field.id, active: field.active }));

  return {
    postItems: postCFItems.concat(postOFItems),
    putItems: putCFItems.concat(putOFItems),
  };
}

type CategorizedResp = { optionalFields: CustomField[]; customFields: CustomField[] };

export function getParseResp(list: CustomField[]) {
  const { optionalFields, customFields }: CategorizedResp = list.reduce(
    (acc: CategorizedResp, cur: CustomField) => {
      const { optionalFields, customFields } = acc;
      if (['money_origin', 'job', 'purpose_of_transaction', 'owner', 'address'].some((item) => cur.json_key === item)) {
        optionalFields.push(cur); // prettier-ignore
      } else {
        customFields.push({ ...cur, isAppend: false, error: { type: false, name: false, active: false } }); // prettier-ignore
      }
      return { optionalFields, customFields };
    },
    { optionalFields: [], customFields: [] }
  );

  const optFields = optionalFields.reduce((acc, cur) => ({ ...acc, [cur.json_key]: { id: cur.id, active: cur.active, isAppend: false } }), initialOptionalField); // prettier-ignore

  return { customFields, optionalFields: optFields };
}
