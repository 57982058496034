import { PrivacyPage } from './terms/PrivacyPage';
import { ServicePage } from './terms/ServicePage';
import { PrivacyPage as GudiancePrivacyPage } from './guidance/PrivacyPage';
import { PolicyPage } from './guidance/PolicyPage';

export { LoginPage } from './login/LoginPage';
export { ClientPage } from './client/ClientPage';
export { AdminPage } from './admin/AdminPage';

export const Terms = { PrivacyPage, ServicePage };
export const Guidance = { PrivacyPage: GudiancePrivacyPage, PolicyPage };
