import React, { createContext, useState, useEffect, useContext, useRef } from 'react';
import uuid from 'react-uuid';
import { cloneDeep, omit } from 'lodash';
import classNames from 'classnames';
import { Button, Input } from 'common/components';

const getTreeWithId = (tree: Tree[]) => {
  const treeData: TreeWithId[] = [];
  const copiedTree = cloneDeep(tree);

  const dfs = (tree: Tree[], target: Tree[]) => {
    for (const t of tree) {
      const tWithId = { ...t, id: uuid(), tree: [] };
      if (t.tree.length) {
        // 자식이 있으면,
        // 현재 노드 정보를 parent로 가지고 다시 dfs
        dfs(t.tree, tWithId.tree);
      }
      // 기본적으로는 현재 노드에 id만 추가해서 target에 push
      target.push(tWithId);
    }
  };

  dfs(copiedTree, treeData);

  return treeData;
};

export const getTreeWithoutId = (tree: TreeWithId[]) => {
  const treeData: Tree[] = [];
  const copiedTree = cloneDeep(tree);

  const dfs = (tree: TreeWithId[], target: Tree[]) => {
    for (const t of tree) {
      const tWithoutId = { ...omit(t, 'id', 'tree'), tree: [] };
      if (t.tree.length) {
        // 자식이 있으면,
        // 현재 노드 정보를 parent로 가지고 다시 dfs
        dfs(t.tree, tWithoutId.tree);
      }
      // 기본적으로는 현재 노드에 id만 추가해서 target에 push
      target.push(tWithoutId);
    }
  };

  dfs(copiedTree, treeData);

  return treeData;
};

const updateRecur = (
  tree: TreeWithId[],
  data: { tree: TreeWithId[]; parent?: TreeWithParent },
  depth: number
): TreeWithId[] => {
  const writableTree = cloneDeep(tree);
  const { parent } = data;
  const parents: TreeWithParent[] = [];

  const getParentsRecur = (self: TreeWithParent) => {
    if (self && self.parent) getParentsRecur(self.parent);
    parents.push(self);
  };

  // parent가 없으면 대분류이기 때문에 전체를 교체
  if (!parent) return data.tree;

  getParentsRecur(parent);

  const recur = (self: TreeWithId[], depth: number) => {
    if (depth < parents.length - 1) {
      const idx = self.findIndex((el) => el.id === parents[depth].id);
      recur(self[idx].tree, depth + 1);
    }
    if (depth === parents.length - 1) {
      const target = self.find((el) => el.id === parents[depth].id);
      if (target) target.tree = data.tree;
    }
  };

  recur(writableTree, depth);

  return writableTree;
};

export type Tree = { name: string; score: number; tree: Tree[] };
type TreeWithId = Tree & { id: string; tree: TreeWithId[] };
type TreeWithParent = TreeWithId & { parent?: TreeWithParent };
type Context = {
  next: { title: string }[];
  tree: TreeWithId[];
  limit: number;
  onChangeHandler: (data: { tree: TreeWithId[]; parent?: TreeWithParent }) => void;
  activeItem: TreeWithId | null;
  selectHandler: (data: { node: TreeWithId | null }) => void;
};
const Context = createContext<Context | null>(null);

type LinkedListProvider = {
  next: { title: string }[];
  tree: Tree[];
  children: JSX.Element;
  onChange?: (data: Tree[]) => void;
};

function LinkedListProvider(props: LinkedListProvider) {
  const gtw = getTreeWithId(props.tree);
  const [tree, updateTree] = useState<TreeWithId[]>([{ id: '0', name: 'root', score: 0, tree: gtw }]);
  const [next] = useState(props.next);
  const [activeItem, setActiveItem] = useState<TreeWithId | null>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const onChangeHandler = (data: { tree: TreeWithId[]; parent?: TreeWithParent }) => {
    const updated = updateRecur(tree, data, 0);
    updateTree(updated);
    if (props.onChange) {
      const [updatedTree] = getTreeWithoutId(updated);
      props.onChange(updatedTree.tree);
    }
  };

  const selectHandler = (data: { node: TreeWithId | null }) => {
    setActiveItem(data.node);
  };

  useEffect(() => {
    const clickOutSide = (e: Event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(e.target as Node)) {
        setActiveItem(null);
      }
    };

    document.addEventListener('click', clickOutSide);

    return () => document.removeEventListener('click', clickOutSide);
  }, [wrapperRef.current]);

  return (
    <Context.Provider value={{ tree, next, limit: next.length - 1, onChangeHandler, activeItem, selectHandler }}>
      <div ref={wrapperRef}>{props.children}</div>
    </Context.Provider>
  );
}

type LinkedListItemProps = {
  item: TreeWithId;
  selected: TreeWithParent | null;
  activeItem: TreeWithId | null;
  onClick: (item: TreeWithId) => void;
  onBlur: (name: string) => void;
  onChange: (id: string, name: string) => void;
};
function LinkedListItem(props: LinkedListItemProps) {
  const { item, selected, activeItem, onClick, onBlur, onChange } = props;
  const inputRef = useRef<HTMLInputElement>(null);

  const active = item.id === activeItem?.id;
  const select = item.id === selected?.id;

  useEffect(() => {
    if (inputRef.current && active) {
      inputRef.current.focus();
    }
  }, [inputRef, active]);

  return (
    <li className={classNames({ selected: select })} onClick={() => onClick(item)}>
      <Input
        className="basic"
        value={item.name}
        disabled={!active}
        innerRef={inputRef}
        placeholder="최대 25자 입력 가능"
        maxLength={25}
        keyEnter={onBlur}
        onBlur={() => onBlur(item.name)}
        onChange={(e) => onChange(item.id, e.target.value)}
      />
      <i className={classNames({ hasChild: !!item.tree.length })} />
    </li>
  );
}

type LinkedListProps = {
  parent?: TreeWithParent;
  depth?: number;
  tree: TreeWithId[] | null;
};

function LinkedList(props: LinkedListProps) {
  const { depth = 0, parent } = props;
  const [selected, setSelected] = useState<TreeWithParent | null>(null);

  const [tree, setTree] = useState(props.tree ?? []);
  const childTree = tree.filter((el) => el.id === selected?.id)[0]?.tree ?? [];

  useEffect(() => {
    setTree(props.tree ?? tree);
  }, [props.tree]);

  const context = useContext(Context);
  if (!context) return <h1>Provider 내부에서 사용하세요</h1>;
  const { next, limit, onChangeHandler, selectHandler, activeItem } = context;

  const { title } = next[depth];

  return (
    <>
      <div className="category sub">
        <div className="control">
          <h4>{title}</h4>
          <div className="button-group">
            <Button
              text=""
              type="button"
              disabled={!parent}
              onClick={() => {
                const addData = { score: 0, name: '', tree: [], parent, id: uuid() };
                setTree([...tree, addData]);
                setSelected(addData);
                selectHandler({ node: addData });
              }}
            />
            <Button
              text=""
              type="button"
              disabled={!selected}
              onClick={() => {
                const deletedTree = tree.filter((el) => el.id !== selected?.id);
                setTree(deletedTree);
                onChangeHandler({ tree: deletedTree, parent });
              }}
            />
          </div>
        </div>

        <ul className="content">
          {tree.map((el, index) => {
            return (
              <LinkedListItem
                key={index}
                item={el}
                selected={selected}
                activeItem={activeItem}
                onClick={(item) => {
                  setSelected({ ...item, parent });
                  if (selected?.id === item.id) {
                    selectHandler({ node: item });
                  }
                }}
                onBlur={(name) => {
                  if (name) {
                    onChangeHandler({ tree, parent });
                    selectHandler({ node: null });
                  } else setTree(tree.slice(0, tree.length - 1));
                }}
                onChange={(id, name) => {
                  const nextTree = tree.map((t) => (t.id === id ? { ...t, name } : t));
                  setTree(nextTree);
                }}
              />
            );
          })}
        </ul>
      </div>

      {depth < limit && (
        <LinkedList depth={depth + 1} parent={selected ?? undefined} key={selected?.id} tree={childTree} />
      )}
    </>
  );
}

LinkedList.Provider = LinkedListProvider;
LinkedList.Consumer = Context.Consumer as React.Consumer<Context>;

export default LinkedList;
