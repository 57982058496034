import React from 'react';
// import classNames from 'classnames';
// import { Button } from 'common/components';
// import RaWeightProvider from './weightSetting/WeightContenxt';
// import WeightSetting from './weightSetting/WeightSetting';
import CustomerRisk from './customerRisk/CustomerRisk';
import ProductRisk from './productRisk/ProductRisk';
import NationRisk from './nationRisk/NationRisk';
import './RaSettings.scss';

interface Props {
  use_ra: boolean;
}
function RaSettings({ use_ra }: Props) {
  return (
    <section id="ra-setting-content">
      <CustomerRisk use_ra={use_ra} />
      <ProductRisk use_ra={use_ra} />
      <NationRisk use_ra={use_ra} />
    </section>
  );
}

export default RaSettings;
