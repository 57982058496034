import React, { createContext, useContext, useState } from 'react';

type EddContextProps = {
  edit: { config: boolean; risk: boolean; toggleEdit: (type: 'config' | 'risk') => void };
};
const EddContext = createContext<EddContextProps | null>(null);

interface EddContextProviderProps {
  children: JSX.Element;
}
function EddContextProvider(props: EddContextProviderProps) {
  const [editConfig, setEditConfig] = useState(false);
  const [editRisk, setEditRisk] = useState(false);

  const toggleEdit = (type: 'config' | 'risk') => {
    if (type === 'config') setEditConfig((prev) => !prev);
    if (type === 'risk') setEditRisk((prev) => !prev);
  };

  return (
    <EddContext.Provider value={{ edit: { config: editConfig, risk: editRisk, toggleEdit } }}>
      {props.children}
    </EddContext.Provider>
  );
}

EddContextProvider.Consumer = EddContext.Consumer as React.Consumer<EddContextProps>;

export default EddContextProvider;

export const useEddConext = () => {
  const context = useContext(EddContext);
  if (!context) throw new Error('EddContext is null');
  return context;
};
