type Tree = { name: string; score: number; tree: Tree[] };
export type EddCategory = { id: number; name: string; weight: number };
export type Risk = {
  id: number;
  name: string;
  type: string;
  active: boolean;
  edd_category_id: number;
  score: number;
  deletable: boolean;
  mutable: boolean;
  order: number;
  tree: Tree[];
};
export type NationRisk = {
  country_id: number;
  score: number;
  iso_code2: string;
  iso_code3: string;
  name_en: string;
  name_kr: string;
  warn_labels: string[];
};

const customerRisk: Risk[] = [
  {
    id: 1,
    name: '직업',
    type: 'tree',
    active: true,
    edd_category_id: 1,
    score: 50,
    deletable: false,
    mutable: true,
    order: 1,
    tree: [
      {
        name: '근로소득자',
        score: 0,
        tree: [
          { name: '급여소득자', score: 8, tree: [] },
          { name: '공무원', score: 9, tree: [] },
          { name: '교육계 종사자', score: 7, tree: [] },
          { name: '카지노/대부업 종사자', score: 6, tree: [] },
          { name: '가상자산 종사자', score: 3, tree: [] },
        ],
      },
      {
        name: '자영업자',
        score: 0,
        tree: [
          { name: '개인사업자/자영업자', score: 24, tree: [] },
          { name: '숙박 및 요식업/여행사', score: 27, tree: [] },
          { name: '건설업', score: 29, tree: [] },
          { name: '농축수산업', score: 28, tree: [] },
          { name: '전문직', score: 31, tree: [] },
          { name: '귀금속/예술품 판매업', score: 33, tree: [] },
          { name: '환전상', score: 47, tree: [] },
          { name: '전당포/대부업', score: 40, tree: [] },
          { name: '카지노', score: 40, tree: [] },
        ],
      },
      { name: '무직', score: 12, tree: [] },
      { name: '주부', score: 12, tree: [] },
      { name: '종교인', score: 30, tree: [] },
      { name: '정년퇴직자', score: 22, tree: [] },
      { name: '학생', score: 23, tree: [] },
      { name: '정치인', score: 47, tree: [] },
      { name: '프리랜서', score: 36, tree: [] },
    ],
  },
  {
    id: 2,
    name: '거래 목적',
    type: 'tree',
    active: true,
    edd_category_id: 1,
    score: 30,
    deletable: false,
    mutable: true,
    order: 2,
    tree: [
      { name: '급여 및 생활비', score: 10, tree: [] },
      { name: '저축 및 투자', score: 0, tree: [] },
      { name: '사업상 거래', score: 0, tree: [] },
      { name: '보험료 납부', score: 0, tree: [] },
      { name: '공과금 납부', score: 0, tree: [] },
      { name: '카드 대금', score: 0, tree: [] },
      { name: '대출원리금 상환', score: 0, tree: [] },
      { name: '상속 증여성 거래', score: 0, tree: [] },
    ],
  },
  {
    id: 3,
    name: '자금의 원천',
    type: 'tree',
    active: true,
    edd_category_id: 1,
    score: 0,
    deletable: false,
    mutable: true,
    order: 3,
    tree: [
      { name: '금융소득(가상자산 포함)', score: 0, tree: [] },
      { name: '퇴직금', score: 0, tree: [] },
      { name: '생활비/용돈', score: 0, tree: [] },
      { name: '상속/증여', score: 0, tree: [] },
      { name: '양도소득', score: 0, tree: [] },
      { name: '대출금/차입금', score: 0, tree: [] },
      { name: '부동산 임대소득', score: 0, tree: [] },
      { name: '연금', score: 0, tree: [] },
      { name: '장학금/상금', score: 0, tree: [] },
      { name: '채굴/이벤트 보상', score: 0, tree: [] },
    ],
  },
  {
    id: 4,
    name: '소득 규모',
    type: 'tree',
    active: true,
    edd_category_id: 1,
    score: 0,
    deletable: false,
    mutable: true,
    order: 4,
    tree: [
      { name: '1억원 이하', score: 0, tree: [] },
      { name: '1억원 초과 3억원 이하', score: 0, tree: [] },
      { name: '3억원 초과 5억원 이하', score: 0, tree: [] },
      { name: '5억원 초과', score: 0, tree: [] },
    ],
  },
  {
    id: 5,
    name: '국내 거주 여부',
    type: 'tree',
    active: true,
    edd_category_id: 1,
    score: 0,
    deletable: false,
    mutable: false,
    order: 5,
    tree: [
      { name: '국내 거주', score: 0, tree: [] },
      { name: '비거주(해외 거주)', score: 0, tree: [] },
    ],
  },
  {
    id: 6,
    name: '실소유자 여부',
    type: 'tree',
    active: true,
    edd_category_id: 1,
    score: 0,
    deletable: false,
    mutable: false,
    order: 6,
    tree: [
      { name: '네', score: 0, tree: [] },
      { name: '아니오', score: 0, tree: [] },
    ],
  },
];

const productRisk: Risk[] = [
  {
    id: 1,
    name: '상품 및 서비스 종류',
    type: 'tree',
    active: true,
    edd_category_id: 2,
    score: 0,
    deletable: false,
    mutable: true,
    order: 1,
    tree: [
      { name: '암호화폐 구매 및 거래', score: 0, tree: [] },
      { name: '암호화폐 보관', score: 0, tree: [] },
      { name: '암호화폐 대출', score: 0, tree: [] },
      { name: '대출', score: 0, tree: [] },
      { name: '보험', score: 0, tree: [] },
      { name: '예금 및 적금', score: 0, tree: [] },
      { name: '투자 및 펀드 참여', score: 0, tree: [] },
    ],
  },
];

const nationRisk: NationRisk[] = [
  {
    country_id: 9,
    score: 15,
    iso_code2: 'GU',
    iso_code3: 'GUM',
    name_en: 'Guam',
    name_kr: '괌',
    warn_labels: ['블랙리스트', '그레이리스트', 'EU 비협조국', '높은 부패 수준', 'UN Sanction 리스트'],
  },
  {
    country_id: 14,
    score: 20,
    iso_code2: 'GW',
    iso_code3: 'GNB',
    name_en: 'Guinea-Bissau',
    name_kr: '기니비사우',
    warn_labels: ['UN Sanction 리스트'],
  },
  {
    country_id: 31,
    score: 0,
    iso_code2: 'KR',
    iso_code3: 'KOR',
    name_en: 'Republic of Korea',
    name_kr: '대한민국',
    warn_labels: [],
  },
  {
    country_id: 35,
    score: 0,
    iso_code2: 'DE',
    iso_code3: 'DEU',
    name_en: 'Germany',
    name_kr: '독일',
    warn_labels: [],
  },
  {
    country_id: 45,
    score: 50,
    iso_code2: 'LY',
    iso_code3: 'LBY',
    name_en: 'Libya',
    name_kr: '리비아',
    warn_labels: ['높은 부패 수준', 'UN Sanction 리스트'],
  },
  {
    country_id: 72,
    score: 0,
    iso_code2: 'US',
    iso_code3: 'USA',
    name_en: 'United States of America',
    name_kr: '미국',
    warn_labels: [],
  },
  {
    country_id: 75,
    score: 0,
    iso_code2: 'MM',
    iso_code3: 'MMR',
    name_en: 'Republic of the Union of Myanmar',
    name_kr: '미얀마',
    warn_labels: [],
  },
  {
    country_id: 82,
    score: 0,
    iso_code2: 'BD',
    iso_code3: 'BGD',
    name_en: "People's Republic of Bangladesh",
    name_kr: '방글라데시',
    warn_labels: [],
  },
  {
    country_id: 100,
    score: 20,
    iso_code2: 'BR',
    iso_code3: 'BRA',
    name_en: 'Brasil',
    name_kr: '브라질',
    warn_labels: ['높은 부패 수준'],
  },
  {
    country_id: 125,
    score: 0,
    iso_code2: 'CH',
    iso_code3: 'CHE',
    name_en: 'Switzerland',
    name_kr: '스위스',
    warn_labels: [],
  },
  {
    country_id: 182,
    score: 20,
    iso_code2: 'KP',
    iso_code3: 'PRK',
    name_en: "Democratic People's Republic of Korea",
    name_kr: '조선민주주의인민공화국',
    warn_labels: ['EU 비협조국', 'UN Sanction 리스트'],
  },
  {
    country_id: 219,
    score: 10,
    iso_code2: 'TH',
    iso_code3: 'THA',
    name_en: 'Thailand',
    name_kr: '태국',
    warn_labels: ['높은 부패 수준', 'UN Sanction 리스트'],
  },
];

const initialData: { eddCategory: EddCategory[]; eddField: Risk[]; eddNation: NationRisk[] } = {
  eddCategory: [
    { id: 1, name: '고객 위험', weight: 0.3 },
    { id: 2, name: '상품 및 서비스 위험', weight: 0.4 },
    { id: 3, name: '국가 위험', weight: 0.3 },
  ],
  eddField: [...customerRisk, ...productRisk],
  eddNation: [...nationRisk],
};

export const eddCategoryFactory: () => [() => EddCategory[], (nextData: EddCategory[]) => void] = () => {
  let data = initialData.eddCategory;

  const setData = (nextData: EddCategory[]) => {
    data = nextData;
  };

  const getData = () => data;

  return [getData, setData];
};

export const raCategoryMap = {
  1: '고객 위험',
  2: '상품 및 서비스 위험',
  3: '국가 위험',
} as const;

export const eddFieldFactory: () => [(category_id: number) => Risk[], (nextData: Risk[]) => void] = () => {
  let data = initialData.eddField;

  const setData = (nextData: Risk[]) => {
    data = nextData;
  };

  const getData = (category_id: number) => data.filter((el) => el.edd_category_id === category_id);

  return [getData, setData];
};

export const getEddCountries = ({ warn_only = true }) =>
  initialData.eddNation.filter((nation) => (warn_only ? !!nation.warn_labels.length : nation));

export const eddNationFactory: () => [() => NationRisk[], (nextData: NationRisk[]) => void] = () => {
  let data = getEddCountries({ warn_only: true });

  const setData = (nextData: NationRisk[]) => {
    data = nextData;
  };

  const getData = () => data;

  return [getData, setData];
};

export type RaThresholdLogsType = {
  id: number;
  admin_id: number;
  before: [number, number];
  after: [number, number];
  created_at?: string;
  user_id: string;
  user_name: string;
};

export const raThresholdLogs = [
  {
    id: 11,
    admin_id: 1,
    before: [5, 88],
    after: [1, 5],
    created_at: '2023-04-04T08:50:31Z',
    user_id: 'user0001',
    user_name: '어드민',
  },
  {
    id: 10,
    admin_id: 1,
    before: [5, 88],
    after: [5, 88],
    created_at: '2023-04-04T08:45:44Z',
    user_id: 'user0001',
    user_name: '어드민',
  },
  {
    id: 7,
    admin_id: 1,
    before: [15, 95],
    after: [15, 95],
    created_at: '2023-03-29T01:53:32Z',
    user_id: 'user0001',
    user_name: '어드민',
  },
  {
    id: 5,
    admin_id: 1,
    before: [30, 70],
    after: [35, 75],
    created_at: '2023-03-28T09:28:17Z',
    user_id: 'user0001',
    user_name: '어드민',
  },
  {
    id: 4,
    admin_id: 1,
    before: [45, 79],
    after: [30, 70],
    created_at: '2023-03-28T09:02:22Z',
    user_id: 'user0001',
    user_name: '어드민',
  },
];
export const raThresholdLogsFactory: () => [
  () => RaThresholdLogsType[],
  (nextData: RaThresholdLogsType) => void
] = () => {
  let data: RaThresholdLogsType[] = [];

  const setData = (nextData: RaThresholdLogsType) => {
    data = [nextData, ...data];
  };

  const getData = () => data;

  return [getData, setData];
};

type raThresholdSetter = {
  nextData: [number, number];
  info: { user_name: string; name: string; id: number };
};
export const raThresholdFactory: () => [
  () => [number, number],
  ({ nextData, info }: raThresholdSetter) => void,
  () => RaThresholdLogsType[]
] = () => {
  const [getThresholdLogs, setThresholdLogs] = raThresholdLogsFactory();
  let data: [number, number] = [30, 70];

  const setData = ({ nextData, info }: raThresholdSetter) => {
    const now = new Date();
    const created_at = `${now.getFullYear()}-${
      now.getMonth() + 1
    }-${now.getDate()} ${now.getHours()}:${now.getMinutes()}`;
    const { id, user_name, name } = info;

    const logs = {
      id: getThresholdLogs().length + 1,
      admin_id: id,
      before: data,
      after: nextData,
      created_at,
      user_id: user_name,
      user_name: name,
    };
    setThresholdLogs(logs);

    data = nextData;
  };

  const getData = () => data;

  return [getData, setData, getThresholdLogs];
};
