/* eslint-disable import/no-webpack-loader-syntax */
/* eslint-disable @typescript-eslint/no-var-requires */
import { validator } from 'common/components';
import 'common/components/module/module.scss';
import 'common/scss/index.scss';
import 'common/scss/reset.scss';
import { excutions } from 'common/validations';
import React from 'react';
import Router from 'router/Router';
import { ThemeProvider } from 'styled-components';

const style = require('scss-extract-loader!./common/scss/utils.scss');

const theme: { [key: string]: string } = {};
for (const key in style.global) theme[key] = style.global[key].declarations[0].expression;

function App() {
  validator.extensions(excutions); // Validator config
  return (
    <ThemeProvider theme={theme}>
      <Router />
    </ThemeProvider>
  );
}

export default App;
