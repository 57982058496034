import React, { useContext, useEffect, useReducer } from 'react';
import { produce } from 'immer';
// import { api, process } from 'common/functions';
import { ContentContext } from 'layout/admin/content/Content';
import RaWeightProvider from '../context/WeightContext';
import Promotion from '../promotion/Promotion';
import Forbidden from '../forbidden/Forbidden';
import RealScoreContent from './RealScoreContent';
import './RealScoreContainer.scss';

type Data = {
  configs: { allow_custom_field: boolean; use_ra: boolean };
};
export type State = {
  edit: boolean;
  data: Data;
  defaultData: Data;
};

export type Action =
  | { type: 'setEdit'; payload: boolean }
  | { type: 'setData'; payload: Partial<Data> }
  | { type: 'setDefaultData'; payload: Partial<Data> };

const initialData = {
  configs: { allow_custom_field: false, use_ra: false },
};
const initialState: State = {
  edit: false,
  data: { ...initialData },
  defaultData: { ...initialData },
};

const reducer = (state: State, action: Action) => {
  return produce(state, (draft) => {
    // prettier-ignore
    switch (action.type) {
      case 'setEdit': draft['edit'] = action.payload; break;
      case 'setData': draft['data'] = { ...draft.data, ...action.payload }; break;
      case 'setDefaultData': draft['defaultData'] = { ...draft.defaultData, ...action.payload }; break;
    }
  });
};
// TODO: API int
// const get = (url: string) => {
//   return process(
//     api.reqData({ url }),
//     api.get.request,
//     api.fullFilled(({ response }) => {
//       if (response?.data.list) return response?.data.list;
//       return undefined;
//     })
//   );
// };

// const put = (url: string, data: { use_ra: string } | { customer_id: number; module: { custom_field: boolean } }) => {
//   return process(api.reqData({ url, data }), api.put.request);
// };

type ResponseConfig = { key: keyof Data['configs']; value: boolean };
const parseConfigs = (list: ResponseConfig[]) => {
  return list.reduce((acc, cur) => {
    if (cur.key === 'allow_custom_field' || cur.key === 'use_ra') {
      return { ...acc, [cur.key]: cur.value };
    }
    return acc;
  }, initialData.configs);
};
const testConfigs = (a: boolean, b: boolean): ResponseConfig[] => [
  { key: 'allow_custom_field', value: a },
  { key: 'use_ra', value: b },
];

function RealScoreContainer() {
  const { setLoading } = useContext(ContentContext);
  const [state, setState] = useReducer(reducer, initialState);
  const { configs } = state.defaultData;

  const loadHandler = async () => {
    setLoading(true);

    const [list] = await Promise.all([testConfigs(true, true)]);
    const configs = parseConfigs(list);
    // TODO: ra 별 항목로 GET 해서 RaType 함수에 넘겨줘야 함

    setState({ type: 'setData', payload: { configs } });
    setState({ type: 'setDefaultData', payload: { configs } });

    setLoading(false);
  };

  useEffect(() => {
    loadHandler();
  }, []);

  return (
    <div id="admin-manage-ra-realscore-container">
      <h3>위험별 점수 및 실제 반영 점수</h3>
      {configs.allow_custom_field ? (
        <>
          {configs.use_ra ? (
            <RaWeightProvider>
              <RealScoreContent />
            </RaWeightProvider>
          ) : (
            <Forbidden
              content={
                <>
                  <p>EDD 및 RA 기능을 활성화 하신 후</p>
                  <p>실제반영 점수를 확인하실 수 있습니다</p>
                </>
              }
            />
          )}
        </>
      ) : (
        <Promotion />
      )}
    </div>
  );
}

export default RealScoreContainer;
