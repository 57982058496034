import React, { useState } from 'react';
import classNames from 'classnames';
import { modal } from 'layout';
import { Accordion, Button, Input, Modal } from 'common/components';
import { FlatTree, flatTreeToTree, treeToFlatTree } from './util';
import { Data } from './customerRisk/CustomerRisk';
import Modal_RaDropdown from './modal/Modal_RaDropdown';
import { Tree } from './modal/linkedList/LinkedList';
import './RiskItem.scss';

const overMaxScore = (max: number, score: number): boolean => max < score;

interface Props {
  item: Data;
  edit: boolean;
  active: boolean;
  use_ra: boolean;
  activeComp: JSX.Element;
  updateRaItem: (item: Data) => void;
  removeRaItem: (item: Data) => void;
  children?: ({ item, index }: { item: { names: string[]; edd_value: number }; index: number }) => React.ReactNode;
}
function RiskItem(props: Props) {
  const { item, edit, active, use_ra, activeComp, updateRaItem, removeRaItem } = props;
  const { score, tree } = item;
  const [expand, setExpand] = useState(false);

  // const maxScore = 100 * ra.weight * weight;
  const flatTree = treeToFlatTree(tree);
  const checkScore = flatTree.every((ft) => ft.score <= score);

  const handleScoreChange = (nextFlatTree: FlatTree[]) => {
    const tree = flatTreeToTree(nextFlatTree);
    updateRaItem({ ...item, tree });
  };

  const submitHandler = (name: string, nextTree: Tree[]) => {
    updateRaItem({ ...item, name, tree: nextTree });
  };

  return (
    <div className={classNames('accordion__section', { expand, inactive: !active })}>
      <Accordion
        active={expand}
        onClick={() => setExpand((prev) => !prev)}
        title={
          <>
            <div className="item__title">
              <div>
                <b>{item.name}</b>
              </div>
              <div className={classNames({ hide: !edit })}>{activeComp}</div>
              <div className={classNames('max__score', { hide: !use_ra, edit, error: item.active && !checkScore })}>
                <span>최대 점수</span>
                {edit ? (
                  <>
                    <Input
                      className={classNames('basic', { error: item.active && !checkScore })}
                      styles={{ width: 60 }}
                      disabled={!active}
                      type="text"
                      value={score}
                      onChange={(e) => {
                        const { value } = e.target;
                        let numValue = Number(value.replace(/[^\d]/g, ''));
                        numValue = numValue > 100 ? 100 : numValue;
                        updateRaItem({ ...item, score: numValue });
                      }}
                    />
                    점
                  </>
                ) : (
                  <div>{score}점</div>
                )}
              </div>
            </div>
            {item.deletable && edit ? (
              <Modal
                content={({ closeHandler }) => (
                  <div id="modal-ra-item-remove">
                    <modal.Container styles={{ width: 500 }}>
                      <modal.Header align="center">항목 삭제</modal.Header>
                      <modal.Content>
                        <div className="detail">
                          해당 항목을 삭제하시겠습니까?
                          <br />
                          삭제 시 이전에 입력한 선택지는 모두 초기화됩니다.
                        </div>
                      </modal.Content>
                      <modal.Footer
                        submitText="삭제"
                        submitHandler={() => removeRaItem(item)}
                        closeHandler={closeHandler}
                      />
                    </modal.Container>
                  </div>
                )}
              >
                <i className="accordion__remove__icon" />
              </Modal>
            ) : (
              <></>
            )}
          </>
        }
      >
        <div className="item__subtree header">
          <span>선택지</span>
        </div>
        <div className="item__subtree header">
          <span>대분류</span>
          <span>중분류</span>
          <span>소분류</span>
          <span className={classNames({ hide: !use_ra })}>점수</span>
        </div>
        {flatTree.map((ft, i) => {
          const [c1, c2, c3] = ft.names;
          const error = overMaxScore(score, ft.score) && active;
          return (
            <div className="item__subtree" key={i}>
              <span>{c1}</span>
              <span>{c2}</span>
              <span>{c3}</span>
              <span className={classNames(['score', { hide: !use_ra, error }])}>
                {error && <span className="error__message">최대 점수를 넘을 수 없습니다</span>}
                {edit ? (
                  <>
                    <Input
                      className={classNames('basic', { error })}
                      styles={{ width: 60 }}
                      disabled={!active}
                      type="text"
                      value={ft.score}
                      onChange={(e) => {
                        const { value } = e.target;
                        let numValue = Number(value.replace(/[^\d]/g, ''));
                        numValue = numValue > 100 ? 100 : numValue;
                        const newFlatTree = flatTree.map((t, j) => {
                          if (i === j) return { ...t, score: numValue };
                          return t;
                        });
                        handleScoreChange(newFlatTree);
                      }}
                    />
                    점
                  </>
                ) : (
                  <div>{ft.score}점</div>
                )}
              </span>
            </div>
          );
        })}
      </Accordion>
      {edit && expand && active && item.mutable ? (
        <div className="item__subtree footer">
          <Modal
            content={({ closeHandler }) => (
              <Modal_RaDropdown
                closeHandler={closeHandler}
                submitHandler={submitHandler}
                eddCategoryId={item.edd_category_id}
                item={item}
              />
            )}
          >
            <Button type="button" text="선택지 설정" />
          </Modal>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default RiskItem;
