import React, { useReducer, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Footer, Validator, Input } from 'common/components';
import produce from 'immer';
import { api, process } from 'common/functions';
import { useDispatch as useAdminDispatch } from 'common/hooks/admin';
import { authActions as authAdminActions } from 'store/actions/admin';
import { useDispatch as useReviewerDispatch } from 'common/hooks/reviewer';
import { authActions as authReviewerActions } from 'store/actions/reviewer';
import classNames from 'classnames';
import { Layer } from 'layout';
import './TempPassModifyPage.scss';

type Action =
  | { type: 'loading'; payload: boolean }
  | { type: 'password'; payload: string }
  | { type: 'new_password'; payload: string }
  | { type: 'error'; payload: string };

interface State {
  loading: boolean;
  password: string;
  new_password: string;
  error: string;
}

const initialState: State = { loading: false, password: '', new_password: '', error: '' };
const reducer = (state: State, action: Action) => {
  const { type, payload } = action;
  // prettier-ignore
  return produce(state, (draft) => {
    switch (type) {
      case 'loading': draft['loading'] = payload; break;
      case 'password': draft['password'] = payload; break;
      case 'new_password': draft['new_password'] = payload; break;
      case 'error': draft['error'] = payload; break;
    }
  });
};

const TempPassModifyPage: React.FC = () => {
  const { role } = useParams();
  const location = useLocation() as { state: { customer_id: number; username: string; password: string } };
  const dispatch = (role === 'admin' ? useAdminDispatch : useReviewerDispatch)();
  const authActions = role === 'admin' ? authAdminActions : authReviewerActions;
  const [state, setState] = useReducer(reducer, initialState);
  const [samePwd, setSamePwd] = useState(false);

  const submitHandler = async () => {
    const params = { ...location.state, new_password: state.new_password };
    setState({ type: 'loading', payload: true });
    process(
      api.reqData({ url: 'mypage', data: params }),
      api.put.request,
      api.fullFilled(async ({ response }) => {
        if (response) {
          if (location.state.customer_id > -1) {
            await dispatch(
              authActions.login({
                customer_id: params.customer_id,
                username: params.username,
                password: params.new_password,
              })
            );
          }
        }
      })
    );
  };

  const disabled = !!state.error || !state.password || !samePwd || !state.password.length || !state.new_password.length;

  return (
    <>
      <div id="temp-pass" className={role}>
        <div id="temp-pass-form-field">
          <div className="find-title">
            <i />
          </div>

          <section className="form-section">
            <Validator.Provider form onSubmit={submitHandler}>
              <div className="login-text">비밀번호 설정</div>
              <Layer styles={{ marginBottom: 16 }}>
                <Input
                  type="password"
                  className={classNames('basic')}
                  placeholder="새 비밀번호를 입력하세요."
                  validType="realtime"
                  rules={['required', 'password']}
                  onChange={(e) => {
                    const { value } = e.target;
                    setState({ type: 'password', payload: value });
                    if (state.error === '새 비밀번호를 입력해 주세요.') setState({ type: 'error', payload: '' });
                    if (state.new_password === value) {
                      setState({ type: 'error', payload: '' });
                      setSamePwd(true);
                    } else {
                      setState({ type: 'error', payload: '입력하신 비밀번호가 일치하지 않습니다.' });
                      setSamePwd(false);
                    }
                  }}
                />
              </Layer>

              <Layer styles={{ marginBottom: 16 }}>
                <Input
                  type="password"
                  className={classNames('basic')}
                  placeholder="새 비밀번호를 재입력하세요."
                  rules={['required']}
                  error={state.error}
                  onFocus={() => {
                    if (!state.password.length) setState({ type: 'error', payload: '새 비밀번호를 입력해 주세요.' });
                  }}
                  onChange={(e) => {
                    const { value } = e.target;

                    setState({ type: 'new_password', payload: value });

                    if (state.password === value) {
                      setState({ type: 'error', payload: '' });
                      setSamePwd(true);
                    } else {
                      setState({ type: 'error', payload: '입력하신 비밀번호가 일치하지 않습니다.' });
                      setSamePwd(false);
                    }
                  }}
                />
              </Layer>

              <div className="pw-rule-notice">
                <p>9~20자로 영문 대/소문자, 숫자, 특수문자를 포함하세요.</p>
                <p>(특수문자: !@#$%^&)</p>
              </div>
              <Validator.Submit
                watchMode
                text="완료"
                disabled={disabled}
                styles={{ width: '100%', height: 56, radius: 8 }}
              />
            </Validator.Provider>
          </section>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default TempPassModifyPage;
