import React from 'react';
import './Forbidden.scss';

const Forbidden = () => {
  return (
    <div className="admin-manage-custom-forbidden">
      <i className="admin-manage-custom-forbidden-icon" />
      <p>페이지 접근 권한이 없습니다.</p>
      <p>해당 기능을 사용하려면 고객센터로 연락해 주세요.</p>
    </div>
  );
};

export default Forbidden;
