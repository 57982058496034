import React, { useContext, createContext, useReducer, useEffect, Dispatch } from 'react';
import produce from 'immer';
import { Alert } from 'common/components';
import { api, process } from 'common/functions';
import { ContentContext } from 'layout/admin/content/Content';
import { getParseResp, getCustomFieldParameter, initialOptionalField, SPECIFIED_KEY } from './controller';

/**
 * CustomFieldContext
 */
export const CustomFieldContext = createContext<{
  state: State;
  setState: Dispatch<Action>;
  loadHandler: () => void;
  updateHandler: (index: number, rowData: CustomField) => void;
  submitHandler: () => void;
} | null>(null);

export type MoneyOrigin = {
  is_registered: boolean;
  value: Partial<CustomField> | null;
};

/**
 * 직접 등록 항목
 */
// const custom_regex = /field_[0-9]+/g;

export type CustomField = {
  id: number;
  order: number;
  name: string;
  type: 'text' | 'file' | '';
  description: string;
  required: boolean | null;
  active: boolean;
  json_key: string;
  isAppend: boolean;
  // error: CFError;
  error: {
    type: 'text' | 'file' | false;
    name: boolean;
    active: boolean;
  };
};

export type Data = {
  customFields: CustomField[];
  optionalFields: {
    [K in SPECIFIED_KEY]: { id: number; order: number; required: boolean; active: boolean; isAppend: boolean };
  };
};

export type Action =
  | { type: 'setData'; payload: Partial<Data> }
  | { type: 'setDefaultData'; payload: Partial<Data> }
  | { type: 'manual'; payload: boolean }
  | { type: 'drag'; payload: CustomField[] }
  | { type: 'optionalFields'; payload: Data['optionalFields'] };

export interface State {
  data: Data;
  defaultData: Data;
}
const initialState: State = {
  data: {
    customFields: [],
    optionalFields: initialOptionalField,
  },
  defaultData: {
    customFields: [],
    optionalFields: initialOptionalField,
  },
};

const p = (url: string) => {
  return process(
    api.reqData({ url }),
    api.get.request,
    api.fullFilled(({ response }) => response?.data.list)
  );
};

const putCustom = (putReq: Partial<CustomField | undefined>[]): Promise<void>[] => {
  return process(api.reqData({ url: 'custom/field', data: { changes: putReq } }), api.put.request);
};
const postCustom = (postReq: Partial<CustomField> | undefined): Promise<void>[] => {
  return process(api.reqData({ url: 'custom/field', data: { ...postReq } }), api.post.request);
};

const reducer = (state: State, action: Action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case 'setData':
        draft['data'] = { ...draft.data, ...action.payload };
        break;
      case 'setDefaultData':
        draft['defaultData'] = { ...draft.defaultData, ...action.payload };
        break;
      case 'optionalFields':
        draft['data']['optionalFields'] = action.payload;
        break;
      case 'drag':
        draft['data']['customFields'] = action.payload.filter((item) => item);
        break;
    }
  });
};

export function CustomFieldProvider({ children }: { children: JSX.Element }) {
  const { setLoading } = useContext(ContentContext);
  const [state, setState] = useReducer(reducer, initialState);
  const { set } = Alert.Context();

  const loadHandler = async () => {
    setLoading(true);
    const list = await p('custom/field');
    if (list) {
      list.sort((a: CustomField, b: CustomField) => a.order - b.order);

      const { customFields, optionalFields } = getParseResp(list);

      setState({ type: 'setData', payload: { customFields, optionalFields } });
      setState({ type: 'setDefaultData', payload: { customFields, optionalFields } });
    }

    /**
     * 아마도 추가인증 사용여부를 Admin에서 toggle 할 것으로 보임.
     * TODO
     */
    // const useCustomField = configs.filter(
    //   (config: { key: string; value: string | number | boolean }) => config.key === 'use_custom_field'
    // )[0]?.value;

    setLoading(false);
  };

  const updateHandler = (index: number, rowData: CustomField) => {
    const newCustomFields = state.data.customFields.map((customField, idx) => {
      if (idx === index) return { ...rowData };
      return customField;
    });
    setState({ type: 'setData', payload: { customFields: newCustomFields } });
    // setData({ type: 'setError', payload: !!error.value });
  };

  const submitHandler = async () => {
    setLoading(true);

    const { customFields, optionalFields } = state.data;
    const { postItems, putItems } = getCustomFieldParameter(customFields, optionalFields);

    /**
     * TODO: 추가인증은 항목 post 시 기본 active: true로 되어있음.
     * 선택항목에 대해 post 후 활성/비활성 값을 put해줘야 함
     * */
    if (postItems.length) await Promise.all(postItems.map((post) => postCustom(post)));
    if (putItems.length) await Promise.resolve(putCustom(putItems));

    loadHandler();
    set({ success: '추가 인증 항목이 변경되었습니다.' });
  };

  useEffect(() => {
    loadHandler();
  }, []);

  return (
    <CustomFieldContext.Provider value={{ state, setState, loadHandler, updateHandler, submitHandler }}>
      {children}
    </CustomFieldContext.Provider>
  );
}

// context type 보호
export const useCustomContext = () => {
  const context = useContext(CustomFieldContext);
  if (!context) throw new Error('context is null');
  return context;
};
