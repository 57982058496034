import React, { useState, useReducer, useRef, useEffect } from 'react';
import { useDispatch } from 'common/hooks/admin';
import { modal, Layer } from 'layout';
import { Validator, Input, Alert } from 'common/components';
import produce from 'immer';
import { authActions } from 'store/actions/admin';
import { api, process, checkEmail } from 'common/functions';
import classNames from 'classnames';
import { has } from 'lodash';
import './UserModify.scss';
import { Auth } from '../Modal_ModifyMy';

type Data = { name: string; phone_number: string; new_password: string };
type Action =
  | { type: 'loading'; payload: boolean }
  | { type: 'data'; payload: Partial<Data> }
  | { type: 'email'; payload: Partial<{ value: string; message: string; check: boolean }> }
  | { type: 'check_new_password'; payload: Partial<{ value: string; error: string }> };

interface State {
  loading: boolean;
  check_new_password: { value: string; error: string };
  email: { value: string; message: string; check: boolean };
  data: Data;
}

const initialState: State = {
  loading: false,
  check_new_password: { value: '', error: '' },
  email: { value: '', message: '', check: false },
  data: { name: '', phone_number: '', new_password: '' },
};

const reducer = (state: State, action: Action) => {
  // prettier-ignore
  return produce(state, (draft) => {
    switch (action.type) {
      case 'loading': draft['loading'] = action.payload; break;
      case 'data': draft['data'] = { ...draft.data, ...action.payload }; break;
      case 'email': draft['email'] = { ...draft.email, ...action.payload }; break;
      case 'check_new_password': draft['check_new_password'] = {...draft.check_new_password, ...action.payload}; break;
    }
  });
};

interface Props {
  closeHandler: () => void;
  password: string;
  auth: Auth;
}

const UserModify: React.FC<Props> = ({ auth, password, closeHandler }) => {
  const { data: authData, my } = auth;
  const [errorList, setErrorList] = useState<HTMLElement[]>([]);
  const [errorCount, setErrorCount] = useState<number>(0);
  const contentRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const { set } = Alert.Context();

  const initialize = (init: State) => {
    const { name, phone_number, email } = my;
    return { ...init, data: { ...init.data, name, phone_number }, email: { ...init.email, value: email } };
  };

  const [state, setState] = useReducer(reducer, initialState, initialize);
  const { loading, check_new_password, data } = state;

  const defaultData = { name: my.name, phone_number: my.phone_number, email: my.email, new_password: '' };

  const active = api.differ({ ...data, email: state.email.value }, defaultData);
  const styles = { marginTop: 8, marginBottom: 20 };

  // 이메일 중복 확인
  const emailCheckHandler = async () => {
    const result = await checkEmail({ customer_id: authData.customer_id, value: state.email.value });
    setState({ type: 'email', payload: result });
  };

  const submitHandler = async () => {
    setState({ type: 'loading', payload: true });
    const params = { ...data, customer_id: authData.customer_id, username: my.username, password };
    process(
      api.reqData({ url: 'mypage', data: params }),
      api.put.request,
      api.fullFilled(({ response }) => {
        if (response) {
          closeHandler();
          set({ success: '계정 정보 수정이 완료되었습니다.' });
          dispatch(authActions.my());
        }
      })
    );
  };

  const checkNewPw = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (!data.new_password) {
      setState({ type: 'check_new_password', payload: { value, error: '새 비밀번호를 입력해 주세요.' } });
    } else if (e.target.value !== data.new_password) {
      setState({
        type: 'check_new_password',
        payload: { value, error: '입력하신 비밀번호가 일치하지 않습니다.' },
      });
    } else {
      setState({ type: 'check_new_password', payload: { value, error: '' } });
    }
  };

  setTimeout(() => {
    const el = contentRef.current?.getElementsByClassName('jack__validator__error__message');
    if ((el || []).length !== errorList.length) {
      const assign = [...(contentRef.current?.getElementsByClassName('jack__validator__error__message') || [])];
      setErrorList(assign as HTMLElement[]);
    }
  }, 1);

  useEffect(() => {
    setErrorCount(errorList.length);
  }, [errorList]);

  return (
    <Validator.Provider onSubmit={submitHandler} id="admin-user-modify-provider">
      <modal.Header>정보 수정</modal.Header>
      <modal.Content>
        <div className="content" ref={contentRef}>
          <modal.Heading>고객사명</modal.Heading>
          <Layer styles={styles}>
            <Input className="basic" readonly value={authData.company.name} />
          </Layer>

          <modal.Heading>아이디</modal.Heading>
          <Layer styles={styles}>
            <Input className="basic" readonly value={my.username} />
          </Layer>

          <modal.Heading>이름</modal.Heading>
          <Layer styles={styles}>
            <Input
              className="basic"
              rules={['required', 'name']}
              validType="realtime"
              value={data.name}
              onChange={(e) => setState({ type: 'data', payload: { name: e.target.value } })}
            />
          </Layer>

          <modal.Heading>전화번호</modal.Heading>
          <Layer styles={styles}>
            <Input
              className="basic"
              rules={['required', 'phone']}
              validType="realtime"
              maxLength={20}
              value={data.phone_number}
              onChange={(e) => setState({ type: 'data', payload: { phone_number: e.target.value } })}
            />
          </Layer>

          <modal.Heading>이메일</modal.Heading>
          <Validator.Provider onSubmit={emailCheckHandler}>
            <div className="email-check">
              <Input
                className={classNames('basic', { error: state.email.message && !state.email.check })}
                value={state.email.value}
                validType="realtime"
                rules={['email']}
                onChange={(e, error) => {
                  const check = e.target.value === defaultData.email;
                  if (error === true) {
                    setState({ type: 'email', payload: { value: e.target.value, message: '', check } });
                  } else if (error === false) {
                    setState({
                      type: 'email',
                      payload: { value: e.target.value, message: '중복 여부를 확인하세요.', check },
                    });
                  }
                }}
              />
              <Validator.Submit text="중복확인" disabled={state.email.value === defaultData.email} />
              {state.email.message && !state.email.check && (
                <div className={classNames('api-check-message', { error: !state.email.check })}>
                  {state.email.message}
                </div>
              )}
            </div>
          </Validator.Provider>

          <input style={{ display: 'none' }} />

          <modal.Heading optional>새 비밀번호</modal.Heading>
          <Layer styles={styles}>
            <Input
              className="basic"
              type="password"
              value={data.new_password}
              validType="realtime"
              rules={['accountPassword']}
              error={password === data.new_password ? '기존 비밀번호와 동일합니다. 확인 후 수정해주세요.' : ''}
              onChange={(e) => {
                setState({ type: 'data', payload: { new_password: e.target.value } });
                if (
                  check_new_password.error === '입력하신 비밀번호가 일치하지 않습니다.' &&
                  e.target.value === check_new_password.value
                ) {
                  setState({ type: 'check_new_password', payload: { error: '' } });
                }
              }}
            />
          </Layer>

          <modal.Heading optional>새 비밀번호 확인</modal.Heading>
          <Layer styles={styles}>
            <Input
              className="basic"
              type="password"
              value={check_new_password.value}
              error={check_new_password.error}
              onFocus={checkNewPw}
              onChange={checkNewPw}
            />
          </Layer>
        </div>
      </modal.Content>
      <modal.Footer
        loading={loading}
        closeHandler={closeHandler}
        disabled={
          password === data.new_password ||
          !active ||
          (has(active, 'email') && !state.email.check) ||
          !!state.check_new_password.error ||
          !!errorCount
        }
      />
    </Validator.Provider>
  );
};

export default UserModify;
