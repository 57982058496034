/* eslint-disable react/no-danger */
import React, { useState, useRef, Dispatch, useReducer } from 'react';
import { Input, Button } from 'common/components';
import { api, process, findCustomer } from 'common/functions';
import classNames from 'classnames';
import { debounce } from 'lodash';
import produce from 'immer';
import type { Action as CtAction } from '../SignUpPage';
import './Client.scss';

type Action =
  | { type: 'loading'; payload: boolean }
  | { type: 'customer'; payload: Partial<{ name: string; error: string; id: number }> }
  | { type: 'user'; payload: Partial<{ name: string; password: string; error: string }> };

interface State {
  loading: boolean;
  customer: { name: string; error: string; id: number };
  user: { name: string; password: string; error: string };
}

const initialState: State = {
  loading: false,
  customer: { name: '', error: '', id: -1 },
  user: { name: '', password: '', error: '' },
};

const reducer = (state: State, action: Action) => {
  const { type, payload } = action;
  // prettier-ignore
  return produce(state, (draft) => {
    switch (type) {
      case 'loading': draft['loading'] = payload; break;
      case 'customer': draft['customer'] = { ...state.customer, ...payload }; break;
    }
  });
};

interface Props {
  setState: Dispatch<CtAction>;
}

const Client: React.FC<Props> = ({ setState: ctSetState }) => {
  const [state, setState] = useReducer(reducer, initialState);
  const timer = () => {
    const info = async (name: string) => {
      const result = await findCustomer({ customer_name: name });
      if (result.id === -1) result.error = '등록되지 않은 고객사명입니다.';
      setState({ type: 'customer', payload: result });
    };
    return debounce(info, 500);
  };
  const [customerCheck] = useState(timer);
  const inputRef = useRef<HTMLInputElement>(null);

  const submitHandler = (e: React.FormEvent) => {
    e.preventDefault();
    setState({ type: 'loading', payload: true });
    process(
      api.reqData({ url: 'find/customer', data: { customer_name: inputRef.current?.value || '' } }),
      api.post.request,
      api.fullFilled(({ response }) => {
        if (response) {
          const { customer_id } = response.data;
          ctSetState({ type: 'customer_id', payload: customer_id });
          ctSetState({ type: 'step', payload: 3 });
        }
        setState({ type: 'loading', payload: false });
      })
    );
  };

  return (
    <form className="client" onSubmit={submitHandler}>
      <p className="title">고객사명</p>
      <Input
        innerRef={inputRef}
        className={classNames('basic', {
          error: state.customer.error,
          success: !(state.customer.id === -1 || !state.customer.name),
        })}
        placeholder="고객사명을 입력하세요."
        onChange={(e) => {
          const name = e.target.value;
          setState({ type: 'customer', payload: { name, error: '', id: -1 } });
          if (name) {
            customerCheck.cancel();
            customerCheck(name);
          }
          if (!name.length) customerCheck.cancel();
        }}
      />

      {state.customer.error && (
        <div className={classNames('api-check-message', { error: state.customer.error })}>{state.customer.error}</div>
      )}

      <div className="next-field">
        <Button text="뒤로" onClick={() => ctSetState({ type: 'step', payload: 1 })} />
        <Button
          type="submit"
          loading={state.loading}
          text="다음"
          disabled={state.customer.id === -1 || !state.customer.name}
        />
      </div>
    </form>
  );
};

export default Client;
