import React, { SetStateAction, Dispatch } from 'react';
import { isEqual } from 'lodash';
import { Button, Popover, Validator } from 'common/components';
import { useCustomContext } from '../../context/CustomFieldContext';
import '../Custom.scss';

interface Props {
  edit: boolean;
  setEdit: Dispatch<SetStateAction<boolean>>;
}

const Header = ({ edit, setEdit }: Props) => {
  const { state, setState } = useCustomContext();
  const { data, defaultData } = state;
  // 신규항목의 입력조건 만족하는지 확인
  const requiredFulfilled =
    data.customFields.filter(
      (cf) =>
        (cf.isAppend && (cf.type === '' || cf.name === '' || cf.required === null)) ||
        cf.error.type ||
        cf.error.name ||
        cf.error.active
    ).length === 0;

  const customFieldChange =
    data.customFields.filter((cf, index) => JSON.stringify(cf) !== JSON.stringify(defaultData.customFields[index]))
      .length !== 0;

  const customFieldActive = !requiredFulfilled || !customFieldChange;
  const optionalFieldActive = isEqual(data.optionalFields, defaultData.optionalFields);

  return (
    <div className="header">
      <div className="title custom-field--title">추가 인증 서비스</div>
      <Popover
        position="right center"
        clickOutside
        content={() => (
          <div id="admin-manage-customfield-popover-content" className="description">
            고객 신원 확인의 정확도를 높이기 위한 추가 절차이 며 자금 출처, 주소, 증명서 등을 요청할 수 있습니다.
          </div>
        )}
      >
        <i className="title-info" />
      </Popover>
      <div className="button-group">
        {!edit ? (
          <Button text="편집" onClick={() => setEdit(true)} />
        ) : (
          <>
            <Button
              text="취소"
              onClick={() => {
                setState({ type: 'setData', payload: defaultData });
                setEdit(false);
              }}
            />
            <Validator.Submit disabled={customFieldActive && optionalFieldActive} text="완료" />
          </>
        )}
      </div>
    </div>
  );
};

export default Header;
