import React, { useState } from 'react';
import classNames from 'classnames';
import { /* Switch, */ Validator } from 'common/components';
import { useCustomContext } from '../context/CustomFieldContext';
import Header from './header/Header';
import CustomFieldOptional from './optional/CustomfieldOptional';
import CustomFieldDirect from './direct/Direct';
import './Custom.scss';

const Custom = () => {
  const { submitHandler } = useCustomContext();
  const [edit, setEdit] = useState(false);
  // const [useCustomField, setUseCustomField] = useState(false);

  const onSubmit = () => {
    submitHandler();
    setEdit(false);
  };

  return (
    <Validator.Provider onSubmit={onSubmit}>
      <section id="admin-manage-customfield-setting">
        <Header edit={edit} setEdit={setEdit} />
        {/* <div className="use-custom-field">
          <div>
            <h4>사용자 화면 적용 여부</h4>
            <div>고객의 ekyc 인증 단계에 아래 설정한 항목의 적용 여부를 결정합니다.</div>
          </div>
          <Switch
            className={classNames({ readonly: !edit })}
            disabled={!edit}
            value={useCustomField}
            onChange={(value) => {
              console.log({ value });
              setUseCustomField(!!value);
            }}
          />
        </div> */}
        <div className={classNames('customfield-wrapper', { active: edit })}>
          <CustomFieldOptional edit={edit} />
          <CustomFieldDirect edit={edit} />
        </div>
      </section>
    </Validator.Provider>
  );
};

export default Custom;
