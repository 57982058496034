import React, { useEffect, useReducer, useContext } from 'react';
import { toPairs, pick, pickBy, isObject } from 'lodash';
import { api, process } from 'common/functions';
import { Button, Alert, Accordion, CheckBox, Modal } from 'common/components';
import { NullishGuard } from 'hoc';
import produce from 'immer';
import { ContentContext } from 'layout/reviewer/content/Content';
import type { Data as RowData } from '../CtReviewTable';
import IdCard from './contents/idcard/IdCard';
import Face from './contents/face/Face';
import Account from './contents/account/Account';
import ModalRejectReview from './modal/Modal_RejectReview';
import './Expand.scss';
import Custom from './contents/custom/Custom';

export type Custom = {
  [index: string]: CustomField;
};

export type CustomField = {
  field_name: string;
  field_type: 'text' | 'file';
  field_order: number;
  file_name?: string;
  id?: number;
  value?: string;
};

export type Account = {
  account_holder: string;
  account_number: string;
  finance_code: string;
  finance_company: string;
  mod_account_holder: null;
  verified: boolean;
};

type HistoryAccount = Pick<Account, 'account_holder' | 'account_number' | 'finance_code'> & {
  created_at: string;
};
export type Histories = {
  accounts: HistoryAccount[];
};

export type FaceCheck = {
  is_live: boolean;
  is_masked: boolean;
  is_same_person: boolean;
  selfie_image: string;
};

export type IdCard = {
  id_card_image: string;
  id_card_origin: string;
  id_crop_image: string;
  is_manual_input: boolean;
  is_uploaded: boolean;
  modified: boolean;
  modified_ocr_data: null;
  original_ocr_data: string;
  uploaded_type: string;
  verified: boolean;
};

export type Module = {
  account_verification: boolean;
  face_authentication: boolean;
  id_card_ocr: boolean;
  id_card_verification: boolean;
  liveness: boolean;
};

export type Data = {
  account: Account | null;
  face_check: FaceCheck | null;
  id_card: IdCard | null;
  custom: Custom | null;
  module: Module | null;
  birthday: string;
  id: number;
  name: string;
  phone_number: string;
  request_time: string;
  histories: Histories;
};

const title = { id_card: '신분증 인증', face_check: '얼굴 인증', account: '기존 계좌 인증', custom: '추가 인증' };
type Key = keyof typeof title;
type List = (['id_card', IdCard] | ['face_check', FaceCheck] | ['account', Account] | ['custom', Custom])[];
type Toggle = { id_card: boolean; face_check: boolean; account: boolean; custom: boolean };
export type Action =
  | { type: 'setData'; payload: Data }
  | { type: 'toggle'; payload: Partial<Toggle> }
  | { type: 'submitLoading'; payload: boolean }
  | { type: 'isExpired'; payload: boolean }
  | { type: 'confirm'; payload: Key[] };
export interface State {
  data: Data | null;
  toggle: Toggle;
  confirm: Key[];
  submitLoading: boolean;
  isExpired: boolean;
}

const initialState: State = {
  data: null,
  toggle: { id_card: false, face_check: false, account: false, custom: false },
  confirm: [],
  submitLoading: false,
  isExpired: false,
};
const reducer = (state: State, action: Action) => {
  // prettier-ignore
  return produce(state, (draft) => {
    switch(action.type) {
      case 'setData': draft['data'] = action.payload; break;
      case 'toggle': draft['toggle'] = { ...draft.toggle, ...action.payload }; break;
      case 'confirm': draft['confirm'] = action.payload; break;
      case 'submitLoading': draft['submitLoading'] = action.payload; break;
      case 'isExpired': draft['isExpired'] = action.payload; break;
    }
  });
};

interface Props {
  rowData: RowData;
  refresh: () => void;
}

const Expand: React.FC<Props> = ({ rowData, refresh }) => {
  const { setLoading } = useContext(ContentContext);
  const { set } = Alert.Context();
  const [state, setState] = useReducer(reducer, initialState);
  const { toggle, confirm, submitLoading } = state;
  const { id } = rowData;
  const toggleHandler = (payload: Partial<Toggle>) => setState({ type: 'toggle', payload });

  useEffect(() => {
    setLoading(true);
    process(
      api.reqData({ url: `review/manuals/${id}` }),
      api.get.request,
      api.fullFilled(({ response }) => {
        if (response) {
          setState({ type: 'setData', payload: response.data.detail });
          if (response.data.detail.name === 'EXPIRED') setState({ type: 'isExpired', payload: true });
        }
        setLoading(false);
      })
    );
  }, []);

  const submitHandler = async () => {
    setState({ type: 'submitLoading', payload: true });
    process(
      api.reqData({ url: `review/manuals/${id}`, data: { approve: 1, auditor_id: 2 } }),
      api.post.request,
      api.fullFilled(() => {
        refresh();
        set({ success: '심사 승인이 완료되었습니다.' });
      })
    );
  };

  const data = pickBy(pick(state.data, ['id_card', 'face_check', 'account', 'custom']), isObject) as NonNullable<
    Pick<Data, Key>
  >;
  const list = toPairs(data) as List;
  const disabled = list.length !== confirm.length;

  return (
    <NullishGuard data={[state.data]}>
      <section className="reviewer-review-table-tr-expand">
        <div className="content-wrapper">
          <ul>
            {list.map(([k, v], index) => {
              const find = confirm.find((el) => el === k);
              return (
                <li key={k}>
                  <Accordion
                    toggleOnRow
                    active={toggle[k]}
                    onClick={() => {
                      toggleHandler({ [k]: !toggle[k] });
                      if (!find) {
                        const payload = [...confirm, k];
                        setState({ type: 'confirm', payload });
                      }
                    }}
                    title={
                      <div className="title">
                        <CheckBox checked={find ? 1 : 0} className="circular" />
                        <span>{`0${index + 1}`}</span>
                        {title[k]}
                      </div>
                    }
                  >
                    <NullishGuard data={[k === 'id_card', state.data?.module]}>
                      {([, module]) => (
                        <IdCard rowData={rowData} module={module} content={v as IdCard} isExpired={state.isExpired} />
                      )}
                    </NullishGuard>

                    <NullishGuard data={[k === 'face_check', data.id_card]}>
                      {([, idCard]) => (
                        <Face
                          content={v as FaceCheck}
                          idCard={idCard}
                          module={state.data?.module || null}
                          isExpired={state.isExpired}
                        />
                      )}
                    </NullishGuard>

                    <NullishGuard data={[k === 'account', state.data?.histories]}>
                      {([, histories]) => (
                        <Account content={v as Account} histories={histories} isExpired={state.isExpired} />
                      )}
                    </NullishGuard>
                    <NullishGuard data={[k === 'custom']}>
                      <Custom content={v as Custom} isExpired={state.isExpired} />
                    </NullishGuard>
                  </Accordion>
                </li>
              );
            })}
          </ul>
        </div>

        <article>
          <div className="decision-buttons">
            <Button
              loading={submitLoading}
              text="승인"
              onClick={submitHandler}
              disabled={disabled || state.isExpired}
            />
            <Modal
              disabled={disabled}
              content={({ closeHandler }) => (
                <ModalRejectReview id={id} closeHandler={closeHandler} refresh={refresh} />
              )}
            >
              <Button loading={submitLoading} text="거부" disabled={disabled} />
            </Modal>
          </div>

          <p>정보 확인 후 심사를 수행하세요.</p>
        </article>
      </section>
    </NullishGuard>
  );
};

export default Expand;
