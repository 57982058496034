import React, { Dispatch } from 'react';
import { Input } from 'common/components';
import { NullishGuard } from 'hoc';
import type { Data, Action } from '../Expand';
import './Aside.scss';

interface Props {
  data: Data;
  edit: boolean;
  setState: Dispatch<Action>;
}

const Aside: React.FC<Props> = ({ edit, data, setState }) => {
  const { detail } = data;
  const attrs = {
    type: 'number',
    min: '1',
    max: '99',
    styles: { width: 54, height: 22 },
    rules: [{ min: 1, max: 99 }],
  };
  return (
    <aside className="root-page-client-expand-admins">
      <div className="admin-count">
        <h5>관리자 수 설정</h5>
        <div className="count">
          <p className="extras-title">어드민</p>
          <NullishGuard data={[edit]}>
            <Input
              {...attrs}
              value={detail.max_admin.toString()}
              onChange={(e) => {
                let { value } = e.target;
                if (Number(e.target.value) > 99) value = '99';
                setState({ type: 'detail', payload: { max_admin: Number(value) } });
              }}
            />
            명
          </NullishGuard>
          <NullishGuard data={[!edit]}>
            <span>{detail.max_admin}명</span>
          </NullishGuard>
        </div>
        <div className="count">
          <p className="extras-title">심사자</p>
          <NullishGuard data={[edit]}>
            <Input
              {...attrs}
              value={detail.max_reviewer.toString()}
              onChange={(e) => {
                let { value } = e.target;
                if (Number(e.target.value) > 99) value = '99';
                setState({ type: 'detail', payload: { max_reviewer: Number(value) } });
              }}
            />
            명
          </NullishGuard>
          <NullishGuard data={[!edit]}>
            <span>{detail.max_reviewer}명</span>
          </NullishGuard>
        </div>
      </div>
    </aside>
  );
};

export default Aside;
