import React from 'react';
import { M_Table, NoData, Table } from 'common/components';
import { State } from '../CtCriterionContent';
import './CriterionLogsTable.scss';

interface Props {
  state: State;
}

function CriterionLogsTable(props: Props) {
  const { state } = props;
  const { thresholdLogs } = state;
  const header: M_Table.header[] = [
    { title: '변경일시', property: 'created_at', align: 'center' },
    { title: '아이디', property: 'user_id' },
    { title: '이름', property: 'user_name' },
    { title: '변경 전', property: 'before', align: 'center' },
    { title: '변경 후', property: 'after', align: 'center' },
  ];

  return (
    <section className="ra-criterion-logs">
      <h3>변경내역 로그 목록</h3>
      <div className="criterion-content">
        {thresholdLogs.length ? (
          <>
            <Table data={thresholdLogs} header={header}>
              <Table.Head>{({ value }) => value.title}</Table.Head>
              <Table.Body>
                {({ index }) => {
                  const rowData = thresholdLogs[index];
                  console.log({ rowData });

                  return (
                    <Table.Row key={rowData.id} styles={{ height: 'auto' }}>
                      <Table.Tr index={index} style={{ height: 96 }}>
                        {({ rowData, property, value }) => {
                          console.log({ rowData, property, value });
                          if (property === 'before') {
                            const [low, up] = value as unknown as [number, number];
                            return (
                              <>
                                <div>
                                  <span>저위험</span>
                                  <span>0점 ~ {low}점</span>
                                </div>
                                <div>
                                  <span>중위험</span>
                                  <span>
                                    {low + 1}점 ~ {up}점
                                  </span>
                                </div>
                                <div>
                                  <span>고위험</span>
                                  <span>{up + 1}점 ~ 100점</span>
                                </div>
                              </>
                            );
                          }
                          if (property === 'after') {
                            const [low, up] = value as unknown as [number, number];
                            return (
                              <>
                                <div>
                                  <span>
                                    <i />
                                  </span>
                                  <span className="highlight">
                                    <span>0점</span> ~ <span>{low}점</span>
                                  </span>
                                </div>
                                <div>
                                  <span>
                                    <i />
                                  </span>
                                  <span className="highlight">
                                    <span>{low + 1}점</span> ~ <span>{up}점</span>
                                  </span>
                                </div>
                                <div>
                                  <span>
                                    <i />
                                  </span>
                                  <span>{up + 1}점 ~ 100점</span>
                                </div>
                              </>
                            );
                          }
                          return <>{value}</>;
                        }}
                      </Table.Tr>
                    </Table.Row>
                  );
                }}
              </Table.Body>
            </Table>
          </>
        ) : (
          <NoData text="설정값 변경기록이 없습니다." />
        )}
      </div>
    </section>
  );
}

export default CriterionLogsTable;
