import React, { useReducer } from 'react';
import { modal } from 'layout';
import produce from 'immer';
import { Admin } from 'models';
import PwCheck from './confirm/PwCheck';
import UserModify from './modify/UserModify';
import './Modal_ModifyMy.scss';

export type Action = { type: 'step'; payload: number } | { type: 'password'; payload: string };
export interface State {
  step: number;
  password: string;
}

const initialState = { step: 0, password: '' };

const reducer = (state: State, action: Action) => {
  return produce(state, (draft) => {
    // prettier-ignore
    switch(action.type) {
      case 'step': draft['step'] = action.payload; break;
      case 'password': draft['password'] = action.payload; break;
    }
  });
};

export interface Auth {
  data: Admin.Auth.data;
  my: Admin.Auth.my;
}

interface Props {
  closeHandler: () => void;
  auth: Auth;
}

const ModalModifyMy: React.FC<Props> = ({ closeHandler, auth }) => {
  const [state, setState] = useReducer(reducer, initialState);

  return (
    <modal.Container styles={{ width: 500 }} id="admin-reviewer-modal-modify-my">
      {state.step === 0 && <PwCheck setState={setState} closeHandler={closeHandler} my={auth.my} />}
      {state.step === 1 && <UserModify auth={auth} password={state.password} closeHandler={closeHandler} />}
    </modal.Container>
  );
};

export default ModalModifyMy;
