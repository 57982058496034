import React, { useReducer } from 'react';
import { useParams } from 'react-router-dom';
import { Alert, Footer } from 'common/components';
import produce from 'immer';
import classNames from 'classnames';
import FindId from './findId/FindId';
import FindPw from './findPw/FindPw';
import './FindAccountPage.scss';

const findTypes = ['아이디 찾기', '비밀번호 찾기'] as const;
export type FindType = typeof findTypes[number];
type Action = { type: 'type'; payload: FindType };

interface State {
  type: FindType;
}

const initialState: State = { type: '아이디 찾기' };
const reducer = (state: State, action: Action) => {
  const { type, payload } = action;
  // prettier-ignore
  return produce(state, (draft) => {
    switch (type) {
      case 'type': draft['type'] = payload; break;
    }
  });
};

const FindAccountPage: React.FC = () => {
  const { role } = useParams();
  const [state, setState] = useReducer(reducer, initialState);
  const { type } = state;
  // const isDone = password.done;
  const setFindType = (payload: FindType) => setState({ type: 'type', payload });
  const category = (
    <div className="find-category">
      {findTypes.map((text) => (
        <p
          key={text}
          className={classNames({ active: type === text })}
          onClick={() => setState({ type: 'type', payload: text })}
        >
          {text}
        </p>
      ))}
    </div>
  );

  return (
    <Alert.Provider>
      <Alert.Consumer>
        {({ set }) => {
          return (
            <>
              <Alert.Card />
              <div id="find-account" className={role}>
                <div id="find-account-form-field">
                  <div className="find-title">
                    <i />
                  </div>

                  <section className="form-section">
                    {type === '아이디 찾기' && <FindId setFindType={setFindType}>{category}</FindId>}
                    {type === '비밀번호 찾기' && <FindPw set={set}>{category}</FindPw>}
                  </section>
                </div>
              </div>
              <Footer />
            </>
          );
        }}
      </Alert.Consumer>
    </Alert.Provider>
  );
};

export default FindAccountPage;
