import React from 'react';
import classNames from 'classnames';
import { Switch } from 'common/components';
import { useCustomContext } from '../../context/CustomFieldContext';
import './CustomfieldOptional.scss';

const OPTIONAL_ITEMS = {
  자금_원천: [
    '금융소득 (가상자산 포함)',
    '대출금/차입금',
    '퇴직금',
    '부동산 임대소득',
    '생활비/용돈',
    '연금',
    '상속/증여',
    '장학금/상금',
    '양도 소득',
    '채굴/이벤트 보상',
  ],
  직업: ['근로소득자', '자영업자', '정년퇴직자', '프리랜서', '종교인', '정치인', '주부', '학생', '무직'],
  거래_목적: [
    '급여 및 생활비',
    '저축 및 투자',
    '보험료 납부',
    '사업상 거래',
    '공과금 납부',
    '카드 대금',
    '대출원리금 상환',
    '상속 증여성 거래',
  ],
  실_소유주_여부: [],
  주소: [],
};

interface Props {
  edit: boolean;
}
const CustomFieldOptional = ({ edit }: Props) => {
  const { state: { data: { optionalFields }}, setState } = useCustomContext(); // prettier-ignore
  const { money_origin, job, purpose_of_transaction, owner, address } = optionalFields;

  return (
    <section className="customfield-optional-content">
      <div className="header">
        <div className="title subtitle">선택 항목</div>
      </div>
      <div className="content">
        <article className="customfield-option">
          <header className={classNames({ active: money_origin.active })}>
            자금 출처
            <Switch
              className={classNames({ readonly: !edit })}
              disabled={!edit}
              value={money_origin.active}
              onChange={(value) => {
                setState({
                  type: 'optionalFields',
                  payload: { ...optionalFields, money_origin: { ...money_origin, active: !!value } },
                });
              }}
            />
          </header>
          <ul>
            {OPTIONAL_ITEMS['자금_원천'].map((item) => (
              <li key={item}>{item}</li>
            ))}
          </ul>
        </article>
        <article className="customfield-option">
          <header className={classNames({ active: job.active })}>
            직업
            <Switch
              className={classNames({ readonly: !edit })}
              disabled={!edit}
              value={job.active}
              onChange={(value) => {
                setState({ type: 'optionalFields', payload: { ...optionalFields, job: { ...job, active: !!value } } });
              }}
            />
          </header>
          <ul>
            {OPTIONAL_ITEMS['직업'].map((item) => (
              <li key={item}>{item}</li>
            ))}
          </ul>
        </article>
        <article className="customfield-option">
          <header className={classNames({ active: purpose_of_transaction.active })}>
            거래 목적
            <Switch
              className={classNames({ readonly: !edit })}
              disabled={!edit}
              value={purpose_of_transaction.active}
              onChange={(value) => {
                setState({
                  type: 'optionalFields',
                  payload: {
                    ...optionalFields,
                    purpose_of_transaction: { ...purpose_of_transaction, active: !!value },
                  },
                });
              }}
            />
          </header>
          <ul>
            {OPTIONAL_ITEMS['거래_목적'].map((item) => (
              <li key={item}>{item}</li>
            ))}
          </ul>
        </article>
        <article className="customfield-option">
          <header className={classNames({ active: owner.active })}>
            실 소유주 여부
            <Switch
              className={classNames({ readonly: !edit })}
              disabled={!edit}
              value={owner.active}
              onChange={(value) => {
                setState({
                  type: 'optionalFields',
                  payload: { ...optionalFields, owner: { ...owner, active: !!value } },
                });
              }}
            />
          </header>
          <ul>
            {OPTIONAL_ITEMS['실_소유주_여부'].map((item) => (
              <li key={item}>{item}</li>
            ))}
          </ul>
        </article>
        <article className="customfield-option">
          <header className={classNames({ active: address.active })}>
            주소
            <Switch
              className={classNames({ readonly: !edit })}
              disabled={!edit}
              value={address.active}
              onChange={(value) => {
                setState({
                  type: 'optionalFields',
                  payload: { ...optionalFields, address: { ...address, active: !!value } },
                });
              }}
            />
          </header>
          <ul>
            {OPTIONAL_ITEMS['주소'].map((item) => (
              <li key={item}>{item}</li>
            ))}
          </ul>
        </article>
      </div>
    </section>
  );
};

export default CustomFieldOptional;
