import { M_Select, M_Table } from 'common/components';
import { api, process } from 'common/functions';
import { useApiCancel, useMount } from 'common/hooks';
import { produce } from 'immer';
import { ContentContext } from 'layout/root/content/Content';
import { isNumber } from 'lodash';
import type { Order, Pagination } from 'models';
import React, { useContext, useEffect, useReducer } from 'react';
import AdminTable from './AdminTable';

const initPagination = { info: { current: 1, total: 10 }, format: { limit: 20, offset: 0 } };
export type Data = {
  active: boolean;
  email: string;
  id: number;
  is_approve: boolean;
  name: string;
  phone_number: string;
  username: string;
};
export type Action =
  | { type: 'order'; payload: Order }
  | { type: 'setData'; payload: Data[] }
  | { type: 'searchText'; payload: symbol }
  | { type: 'searchKey'; payload: M_Select.item }
  | { type: 'init'; payload?: string }
  | { type: 'pagination'; payload: Pagination }
  | { type: 'expand'; payload: number }
  | { type: 'customer_id'; payload: M_Select.item | null }
  | { type: 'customers'; payload: M_Select.item[] };

export interface State {
  data: Data[];
  header: M_Table.header[];
  searchKey: M_Select.item;
  searchText: symbol;
  order: Order;
  pagination: Pagination;
  customer_id: M_Select.item | null;
  customers: M_Select.item[];
}

const initialState: State = {
  data: [],
  searchKey: { title: '선택', value: 'all' },
  order: { id: 'DESC' },
  searchText: Symbol(''),
  pagination: { ...initPagination },
  customer_id: null,
  customers: [],
  header: [
    { title: '아이디', property: 'username', flex: 2, align: 'center', order: true },
    { title: '이름', property: 'name', flex: 2, align: 'center', order: true },
    { title: '전화번호', property: 'phone_number', flex: 2, align: 'center', order: true },
    { title: '이메일', property: 'email', flex: 2, align: 'center', order: true },
    { title: '활성여부', property: 'active', flex: 2 },
  ],
};

// prettier-ignore
const reducer = (state: State, action: Action): State => {
  return produce(state, (draft) => {
    switch(action.type) {
      case 'setData': draft['data'] = action.payload; break;
      case 'order': draft['order'] = action.payload; break;
      case 'searchText': 
        draft['searchText'] = action.payload; 
        draft.pagination.format['offset'] = 0;
        draft.pagination.info['current'] = 1;
        break;
      case 'searchKey': 
        draft['searchKey'] = action.payload; 
        if(action.payload.value==='all')draft['searchText'] =  Symbol('')
        draft.pagination.format['offset'] = 0;
        draft.pagination.info['current'] = 1;
        break;
      case 'customer_id': draft['customer_id'] = action.payload; break;
      case 'pagination': draft['pagination'] = action.payload; break;
      case 'customers': draft['customers'] = action.payload; break;
    }
  });
};

const CtAdminTable: React.FC = () => {
  const { setLoading } = useContext(ContentContext);
  const [state, setState] = useReducer(reducer, initialState);
  useApiCancel([`customers/${state.customer_id}/admins`]);

  const isLoadable = isNumber(state.customer_id?.value);

  const loadHandler = () => {
    setLoading(true);

    const sort = api.order(state.order);
    const params = {
      start: state.pagination.format.offset,
      count: state.pagination.format.limit,
      keyword_field: state.searchKey.value,
      keyword: state.searchText.description?.trim(),
      order_field: sort.key,
      order: sort.value,
    };

    process(
      api.reqData({ url: `customers/${state.customer_id?.value}/admins`, params }),
      api.get.request,
      api.fullFilled(({ response }) => {
        if (response) {
          setState({ type: 'setData', payload: response.data.list });
          // setState({ type: 'pagination', payload: pagination });
        }
        setLoading(false);
      })
    );
  };

  useEffect(() => {
    process(
      api.reqData({ url: 'customers' }),
      api.get.request,
      api.fullFilled(({ response }) => {
        if (response) {
          const options = response.data.list.map(({ name, id }: Data) => ({ title: name, value: id }));
          setState({ type: 'customers', payload: options });
        }
      })
    );
  }, []);

  useEffect(() => {
    if (isLoadable) loadHandler();
  }, [state.customer_id?.value]);

  useMount(() => {
    if (isLoadable) loadHandler();
  }, [state.order, state.searchText, state.pagination.info.current, state.searchKey]);

  return <AdminTable state={state} setState={setState} loadHandler={loadHandler} isLoadable={isLoadable} />;
};

export default CtAdminTable;
