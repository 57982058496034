import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { useRealScoreContext } from '../../context/RealScoreContext';

const ScoreAccordionDetail = styled.div<{ expand: boolean; maxHeight: number }>`
  transition: max-height 0.3s ease, padding 0.3s ease;
  border-radius: 8px;
  overflow: hidden;
  ${({ expand }) => {
    if (expand) return `max-height: 200px; padding: 8px 0px;`;
    return `max-height: 0; padding: 0;`;
  }}
}}
`;
interface RaScoreTableHeaderProps {
  head: { title: string; width: string };
}
function RaScoreTableHeader(props: RaScoreTableHeaderProps) {
  const { head } = props;
  const { title, width } = head;

  return (
    <span style={{ width }} key={title}>
      {title}
    </span>
  );
}
interface RaScoreTableRowProps {
  raWeight: number;
  riskItemScore: number;
  score: number;
  expand: boolean;
  children: JSX.Element;
}
function RaScoreTableRow(props: RaScoreTableRowProps) {
  const { raWeight, riskItemScore, score, expand: propExpand, children } = props;
  const { shouldUpdate } = useRealScoreContext();
  const realScore = (raWeight * 10 * score) / 10;
  const [expand, setExpand] = useState(false);

  useEffect(() => {
    setExpand(() => propExpand);
  }, [propExpand, shouldUpdate]);

  return (
    <div className="table-row">
      <div className="table-row-content">
        {children}
        <i className={classNames({ expand })} onClick={() => setExpand((prev) => !prev)} />
      </div>
      <ScoreAccordionDetail className="table-row-detail" expand={expand} maxHeight={expand ? 50 : 0}>
        <div>
          <span className="label">항목 최대 점수</span>
          <span className="score">{riskItemScore}점</span>
        </div>
        <div>
          <span className="label">선택지 작성 점수</span>
          <span className="score">{score}점</span>
          <span>X</span>
          <span className="label">위험도 가중치</span>
          <span>{raWeight}</span>
          <span>=</span>
          <span className="label">실제 점수</span>
          <span className="score">{realScore}점</span>
        </div>
      </ScoreAccordionDetail>
    </div>
  );
}

interface RaScoreTableProps {
  header: { title: string; width: string }[];
  children: React.ReactNode;
}
function RaScoreTable(props: RaScoreTableProps) {
  const { header, children } = props;
  return (
    <div className="ra-realscore-table">
      <div className="table-head">
        {header.map((h) => (
          <RaScoreTableHeader key={h.title} head={h} />
        ))}
      </div>
      <div className="table-body">{children}</div>
    </div>
  );
}

RaScoreTable.Row = RaScoreTableRow;

export default RaScoreTable;
