import React, { useState } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { Button } from 'common/components';
import { NullishGuard } from 'hoc';
import { eddFieldFactory, eddNationFactory } from '../../data/data';
import { Risk } from '../../context/WeightContext';
import { useRealScoreContext } from '../../context/RealScoreContext';
import { FlatTree, treeToFlatTree } from '../../ra/settings/util';
import RaScoreTable from './RaScoreTable';
import './RaType.scss';

const RaTypeAccordion = styled.div<{ maxHeight: number }>`
  overflow: hidden;
  transition: max-height 0.3s ease;
  max-height: ${({ maxHeight }) => `${maxHeight}px;`};
`;

const [getFieldData] = eddFieldFactory();
const [getNationData] = eddNationFactory();

const headers = {
  risk: [
    { title: 'No.', width: '5%' },
    { title: '항목', width: '15%' },
    { title: '대분류', width: '22%' },
    { title: '중분류', width: '22%' },
    { title: '소분류', width: '22%' },
    { title: '실제 점수', width: '10%' },
  ],
  nation: [
    { title: 'No.', width: '5%' },
    { title: '항목', width: '15%' },
    { title: '국가', width: '20%' },
    { title: '라벨', width: '55%' },
    { title: '실제 점수', width: '10%' },
  ],
};
interface Props {
  raItem: Risk;
}
function RaType(props: Props) {
  const { raItem } = props;
  const { updater } = useRealScoreContext();
  const [expand, setExpand] = useState(false);
  const [scoreViewType, setScoreViewType] = useState(false);
  const riskData = getFieldData(raItem.id);
  const nationData = raItem.id === 3 ? getNationData() : [];
  const flatAllData: FlatTree[] =
    riskData.reduce((acc, cur) => [...acc, ...treeToFlatTree(cur.tree, cur.name)], [] as FlatTree[]) ?? [];

  const rowExpandHeight = 80 * (raItem.id !== 3 ? flatAllData.length : nationData.length) + (38 + 8) * 2;
  const maxHeight = !expand ? 0 : rowExpandHeight ?? 0;

  const raTableHeaders = raItem.id !== 3 ? headers.risk : headers.nation;

  return (
    <div className="ra-realscore-ratype">
      <div className="jack__accordion__section">
        <div className={classNames('jack__accordion_button', { active: expand })}>
          <div className="accordion__title">
            <span>{raItem.name}</span>
            <span>적용 가중치: {raItem.weight}</span>
          </div>
          <i className="accordion__arrow__icon button" onClick={() => setExpand((prev) => !prev)} />
        </div>
        <RaTypeAccordion maxHeight={maxHeight} className="accordion_content">
          <div className="accordion__item__wrapper">
            <div className="control-pannel">
              <Button
                type="button"
                className={classNames({ active: !scoreViewType })}
                onClick={() => {
                  setScoreViewType(() => false);
                  updater();
                }}
                text="간략히 보기"
              />
              <Button
                type="button"
                className={classNames({ active: scoreViewType })}
                onClick={() => {
                  setScoreViewType(() => true);
                  updater();
                }}
                text="상세히 보기"
              />
            </div>
            <RaScoreTable header={raTableHeaders}>
              <NullishGuard data={[flatAllData]}>
                {flatAllData.map((el, index) => {
                  const { names, score } = el;
                  const [itemName, c1, c2, c3] = names;
                  const riskItemScore = riskData.filter((elem) => elem.name === itemName)[0].score;
                  const realScore = (raItem.weight * 10 * score) / 10;
                  return (
                    <RaScoreTable.Row
                      key={`${itemName}_${index}_${scoreViewType}`}
                      raWeight={raItem.weight}
                      riskItemScore={riskItemScore}
                      score={score}
                      expand={scoreViewType}
                    >
                      <>
                        <span>{index + 1}</span>
                        <span>{itemName}</span>
                        <span>{c1}</span>
                        <span>{c2}</span>
                        <span>{c3}</span>
                        <span className="realscore">{realScore}점</span>
                      </>
                    </RaScoreTable.Row>
                  );
                })}
              </NullishGuard>
              <NullishGuard data={[nationData]}>
                {nationData.map((nation, index) => {
                  const itemName = '국적';
                  const { name_kr, warn_labels, score } = nation;
                  const realScore = (raItem.weight * 10 * score) / 10;
                  return (
                    <RaScoreTable.Row
                      key={`${itemName}_${index}_${scoreViewType}`}
                      raWeight={raItem.weight}
                      riskItemScore={100}
                      score={score}
                      expand={scoreViewType}
                    >
                      <>
                        <span>{index + 1}</span>
                        <span>{itemName}</span>
                        <span>{name_kr}</span>
                        <span className="warn__labels">
                          {warn_labels.map((la, idx) => (
                            <span key={`${la}_${idx}`}>{la}</span>
                          ))}
                        </span>
                        <span className="realscore">{realScore}점</span>
                      </>
                    </RaScoreTable.Row>
                  );
                })}
              </NullishGuard>
            </RaScoreTable>
          </div>
        </RaTypeAccordion>
      </div>
    </div>
  );
}

export default RaType;
