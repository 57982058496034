import React from 'react';
import classNames from 'classnames';
import type { Data as RowData } from '../../../../CtReviewTable';
import { dateFormatter, Ocr } from '../IdCard';

interface Props {
  origin: Ocr;
  modified: Ocr | null;
  rowData: RowData;
  isModified: (keys: (keyof Ocr)[] | keyof Ocr) => boolean;
}

export const DriverLicense: React.FC<Props> = ({ origin, modified, rowData, isModified }) => {
  const issueDate = modified && isModified('issueDate') ? `${modified.issueDate}` : `${origin.issueDate}`;
  const expiryDate2 = modified && isModified('expiryDate2') ? `${modified.expiryDate2}` : `${origin.expiryDate2}`;
  return (
    <>
      <div>
        <h5>· 이름</h5>
        <p className={classNames({ warning: isModified('userName') })}>{rowData.name}</p>
      </div>

      <div>
        <h5>· 운전면허번호</h5>
        <p className={classNames({ warning: modified && isModified('driverNo') })}>
          {modified && isModified('driverNo') ? modified.driverNo : origin.driverNo}
        </p>
      </div>

      <div>
        <h5>· 주민등록 번호</h5>
        <p className={classNames({ warning: modified && isModified(['juminNo1', '_juminNo2']) })}>
          {modified && isModified(['juminNo1', '_juminNo2'])
            ? `${modified.juminNo1}-${modified['_juminNo2'][0]}******`
            : `${origin.juminNo1}-${origin['_juminNo2'][0]}******`}
        </p>
      </div>

      <div>
        <h5>· 발급 일자</h5>
        <p className={classNames({ warning: modified && isModified('issueDate') })}>{dateFormatter(issueDate)}</p>
      </div>

      <div>
        <h5>· 마감 일자</h5>
        <p className={classNames({ warning: modified && isModified('expiryDate2') })}>{dateFormatter(expiryDate2)}</p>
      </div>
    </>
  );
};
