/* eslint-disable react/no-danger */
import React, { useState } from 'react';
import { M_Select, Select, Input } from 'common/components';
import classNames from 'classnames';
import type { State } from '../CtAdminTable';
import './Title.scss';

interface Props {
  state: State;
  handlers: {
    customerId: (selected: M_Select.item | null) => void;
  };
}

const Title: React.FC<Props> = ({ state, handlers }) => {
  const [focus, setFocus] = useState(false);
  const [value, setValue] = useState('');

  const filteredList = state.customers
    .filter((el) => value && (el.title as string).includes(value))
    .map((item) => {
      const title = item.title as string;
      return (
        <li
          key={item.value}
          onClick={() => handlers.customerId(item)}
          dangerouslySetInnerHTML={{ __html: title.replaceAll(value, `<span>${value}</span>`) }}
        />
      );
    });

  return (
    <div id="root-admin-controller-title">
      어드민 관리
      <Select
        placeholder="고객사 선택"
        selected={state.customer_id}
        options={state.customers}
        onChange={handlers.customerId}
      />
      {!state.customer_id && (
        <div className="customer-setting">
          <p>고객사를 검색하여 정보를 관리하세요.</p>
          <div className={classNames('search-customer', { focus })}>
            <Input
              value={value}
              onChange={(e) => setValue(e.target.value)}
              onFocus={() => setFocus(true)}
              onBlur={() => setFocus(false)}
              placeholder="고객사명을 입력하세요."
            />
            <button className="search-button" type="button">
              <i />
            </button>
          </div>
          {value && (
            <ul>
              {filteredList}
              {!filteredList.length && <li className="no-data">검색어와 일치하는 항목이 없습니다.</li>}
            </ul>
          )}
        </div>
      )}
    </div>
  );
};

export default Title;
