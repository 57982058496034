import React, { useEffect, useReducer, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Validator, Input, Footer } from 'common/components';
import { authActions } from 'store/actions/reviewer';
import { findCustomer } from 'common/functions';
import { useDispatch } from 'common/hooks/reviewer';
import { debounce } from 'lodash';
import classNames from 'classnames';
import { Layer } from 'layout';
import produce from 'immer';
import './LoginPage.scss';

type Action =
  | { type: 'loading'; payload: boolean }
  | { type: 'customer'; payload: Partial<{ name: string; error: string; id: number }> }
  | { type: 'user'; payload: Partial<{ name: string; password: string; error: string }> };

interface State {
  loading: boolean;
  customer: { name: string; error: string; id: number };
  user: { name: string; password: string; error: string };
}

const initialState: State = {
  loading: false,
  customer: { name: '', error: '', id: -2 }, // -1: 검사후 결과가 없을시, -2: 초기값
  user: { name: '', password: '', error: '' },
};

const reducer = (state: State, action: Action) => {
  const { type, payload } = action;
  // prettier-ignore
  return produce(state, (draft) => {
    switch (type) {
      case 'loading': draft['loading'] = payload; break;
      case 'customer': draft['customer'] = { ...draft.customer, ...payload }; break;
      case 'user': draft['user'] = { ...state.user, ...payload }; break;
    }
  });
};

export const LoginPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [state, setState] = useReducer(reducer, initialState);
  const timer = () => {
    const info = async (name: string) => {
      const result = await findCustomer({ customer_name: name });
      if (result.id === -1) result.error = '등록되지 않은 고객사명입니다.';
      setState({ type: 'customer', payload: result });
    };
    return debounce(info, 500);
  };
  const [customerCheck] = useState(timer);
  const { customer, user } = state;

  const submitHandler = async () => {
    // 고객사가 존재할때
    if (customer.id > -1) {
      setState({ type: 'loading', payload: true });
      const params = { customer_id: customer.id, username: user.name, password: user.password };
      const { payload: error } = await dispatch(authActions.login(params));
      // 로그인 에러 발생
      if (error) setState({ type: 'user', payload: { error } });
      setState({ type: 'loading', payload: false });
    }
  };

  // 로그인 체크 후 자동 로그인
  useEffect(() => {
    const data = localStorage.getItem('REVIEWER_TOKEN');
    if (data) {
      const authObj = JSON.parse(data);
      const expire = new Date(authObj.expire).getTime();
      const bumperTime = 1000 * 60 * 60; // 1 hour (왜 1시간을 빼는지 이유는 모르겠음)
      if (expire - bumperTime - Date.now() > 0 && !authObj.is_temp_password) navigate('/reviewer/evaluation-review');
      else localStorage.removeItem('REVIEWER_TOKEN');
    }

    return () => {
      customerCheck.cancel();
    };
  }, []);

  return (
    <>
      <div id="reviewer-login">
        <div id="reviewer-login-form-field">
          <div className="signin-title">
            <i />
          </div>
          <section className="form-section">
            <Validator.Provider form onSubmit={submitHandler}>
              <div className="login-text">Login</div>

              <Layer styles={{ marginBottom: 16 }}>
                <Input
                  className={classNames('basic', {
                    error: customer.error,
                    success: !customer.error && customer.id > -2,
                  })}
                  placeholder="고객사명을 입력하세요."
                  onChange={(e) => {
                    const name = e.target.value;
                    setState({ type: 'customer', payload: { name, error: '', id: -2 } });
                    if (name) {
                      customerCheck.cancel();
                      customerCheck(name);
                    }
                    if (!name.length) customerCheck.cancel();
                  }}
                />

                {customer.error && (
                  <div className={classNames('api-check-message', { error: customer.error })}>{customer.error}</div>
                )}
              </Layer>

              <Layer styles={{ marginBottom: 16 }}>
                <Input
                  className="basic"
                  placeholder="아이디"
                  onChange={(e) => setState({ type: 'user', payload: { name: e.target.value, error: '' } })}
                />
              </Layer>

              <Layer styles={{ marginBottom: 16 }}>
                <Input
                  className="basic"
                  type="password"
                  placeholder="비밀번호"
                  onChange={(e) => setState({ type: 'user', payload: { password: e.target.value, error: '' } })}
                />
              </Layer>

              <Validator.Error error={state.user.error} top={40} />

              <Validator.Submit
                text="로그인"
                loading={state.loading}
                styles={{ width: '100%', height: 60 }}
                disabled={customer.id < 0 || !customer.name || !user.name || !user.password}
              />

              <div className="utils-field">
                <Link to="/find-account/reviewer">로그인에 문제가 있나요?</Link>
                <Link to="/sign-up/reviewer">회원가입</Link>
              </div>
            </Validator.Provider>
          </section>
        </div>
      </div>
      <Footer />
    </>
  );
};
