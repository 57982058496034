import React, { useReducer } from 'react';
import produce from 'immer';
import { modal } from 'layout';
import { Button, Validator, TreeViewContainer } from 'common/components';
import type { TreeNode } from 'common/components';
import { adapter } from './controler';
import './Modal_CustomFieldDirect.scss';

type Item = {
  id: number;
  name: string;
  type: string;
  active: boolean;
  edd_category_id: number;
  weight: number;
  tree: { name: string; edd_value: number; tree: Item['tree'] }[];
};

const items: Item = {
  id: 1,
  name: '직업',
  type: 'tree',
  active: true,
  tree: [
    {
      name: '근로소득자',
      edd_value: 0,
      tree: [
        { name: '급여소득자', edd_value: 0, tree: [] },
        { name: '공무원', edd_value: 0, tree: [] },
        { name: '카지노/대부업', edd_value: 0, tree: [] },
      ],
    },
    {
      name: '자영업자',
      edd_value: 0,
      tree: [
        { name: '숙박업', edd_value: 0, tree: [] },
        { name: '건설업', edd_value: 0, tree: [] },
        { name: '전문직', edd_value: 0, tree: [] },
      ],
    },
    {
      name: '무직',
      edd_value: 0,
      tree: [],
    },
  ],
  edd_category_id: 1,
  weight: 0,
};

type Action =
  | { type: 'setState'; payload: Item[] }
  | { type: 'addItem'; payload: Item }
  | { type: 'setEdd'; payload: boolean }
  | { type: 'setRiskType'; payload: '0' | '1' | '2' }
  | { type: 'setName'; payload: string }
  | { type: 'selected'; payload: Item | undefined };

type State = {
  data: Item[];
  edd: boolean;
  riskType: '0' | '1' | '2';
  name: string;
  selected: Item | undefined;
  treeData: TreeNode[];
};

const initialState: State = {
  data: [],
  edd: false,
  riskType: '2',
  name: '',
  selected: undefined,
  treeData: adapter.toTree(items.tree),
};

const reducer = (state: State, action: Action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case 'setState':
        draft['data'] = action.payload;
        break;
      case 'addItem':
        draft['data'] = [...draft.data, action.payload];
        break;
      case 'setEdd':
        draft['edd'] = action.payload;
        break;
      case 'setRiskType':
        draft['riskType'] = action.payload;
        break;
      case 'setName':
        draft['name'] = action.payload;
        break;
      case 'selected':
        draft['selected'] = action.payload;
        break;
    }
  });
};

interface Props {
  closeHandler: () => void;
}
function Modal_CustomFieldDirect({ closeHandler }: Props) {
  const [state] = useReducer(reducer, initialState);
  const { edd, riskType, name } = state;

  const onSubmit = () => {
    console.log('onSub', { edd, riskType, name });
  };

  return (
    <Validator.Provider onSubmit={onSubmit}>
      <modal.Container id="modal-customfield-direct-dropdown" styles={{ width: 720 }}>
        <modal.Header>보기 설정</modal.Header>
        <modal.Content>
          <div className="condition">
            <div className="item">
              <h4>위험 종류</h4>
              고객 위험
            </div>
            <div className="item">
              <h4>항목명</h4>
              직업
            </div>
          </div>
          <TreeViewContainer
            data={state.treeData}
            render={({ item, updateHandler }) => <TreeViewContainer.Node item={item} updateHandler={updateHandler} />}
          >
            <TreeViewContainer.Consumer>
              {({ treeData, treeAddDisabled, addNodeHandler, deleteNodeHandler, treeDeleteDisabled }) => {
                return (
                  <div className="condition treeview">
                    <div className="item">
                      <h4>구성</h4>
                      <div>
                        <Button text="" type="button" disabled={treeAddDisabled} onClick={addNodeHandler} />
                        <Button
                          text=""
                          type="button"
                          disabled={!treeData.length || treeDeleteDisabled}
                          onClick={deleteNodeHandler}
                        />
                      </div>
                    </div>
                  </div>
                );
              }}
            </TreeViewContainer.Consumer>
          </TreeViewContainer>
        </modal.Content>
        <modal.Footer closeHandler={closeHandler} submitText="등록" disabled />
      </modal.Container>
    </Validator.Provider>
  );
}

export default Modal_CustomFieldDirect;
