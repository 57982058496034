import React from 'react';
import { Data } from './customerRisk/CustomerRisk';
import { Tree } from './modal/linkedList/LinkedList';
import Modal_RaAddItemDropdown from './modal/Modal_RaAddItemDropdown';
import './RiskItem.scss';

interface Props {
  item: Data;
  addRaItem: (item: Data) => void;
  closeHandler: () => void;
}
function RiskAddItem(props: Props) {
  const { item, addRaItem, closeHandler } = props;
  const { edd_category_id } = item;

  const submitHandler = (name: string, nextTree: Tree[]) => {
    addRaItem({ ...item, name, tree: nextTree });
  };

  return (
    <div>
      <Modal_RaAddItemDropdown
        closeHandler={closeHandler}
        submitHandler={submitHandler}
        eddCategoryId={edd_category_id}
        item={item}
      />
    </div>
  );
}

export default RiskAddItem;
