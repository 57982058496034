import uuid from 'react-uuid';
import type { TreeNode } from 'common/components';

type Data = {
  // 서버 통신용 tree type
  name: string;
  edd_value: number;
  tree: Data[];
};

export const adapter = {
  toTree: (data: Data[]): TreeNode[] => {
    const tree: TreeNode[] = [];

    const dfs = (data: Data[], target: TreeNode[], depth: number) => {
      for (const d of data) {
        const { name, edd_value } = d;
        const node = { id: uuid(), name, edd_value, edit: false, depth, expand: true, children: [] };
        if (d.tree.length) {
          dfs(d.tree, node.children, depth + 1);
        }
        target.push(node);
      }
    };

    dfs(data, tree, 0);

    return tree;
  },
  toData: (tree: TreeNode[]): Data[] => {
    const data: Data[] = [];

    const dfs = (treee: TreeNode[], target: Data[]) => {
      for (const t of treee) {
        const { name, edd_value } = t;
        const tmp = { name, edd_value, tree: [] };
        if (t.children.length) {
          dfs(t.children, tmp.tree);
        }
        target.push(tmp);
      }
    };

    dfs(tree, data);

    return data;
  },
};
