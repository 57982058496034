import classNames from 'classnames';
import { Button, Modal } from 'common/components';
import { api, process } from 'common/functions';
import React, { useState } from 'react';
import type { Custom as ICustom } from '../../Expand';
import './Custom.scss';

type OptionalCustomField = 'money_origin' | 'job' | 'purpose_of_transaction' | 'owner' | 'address';
const optionalField: { [K in OptionalCustomField]: string } = {
  money_origin: '자금 출처',
  job: '직업',
  purpose_of_transaction: '거래 목적',
  owner: '실 소유주 여부',
  address: '주소',
};

interface Props {
  content: ICustom;
  isExpired: boolean;
}

const Custom: React.FC<Props> = ({ content, isExpired }) => {
  const [image, setImage] = useState<string>('');

  const downloadHandler = (id: number | undefined, file_name: string | undefined) => {
    if (id === undefined) return;
    if (!file_name) return;

    const type = file_name.split('.')[file_name.split('.').length - 1];

    function downloadBase64File(contentType: string, base64Data: string, fileName: string) {
      const linkSource = `data:${contentType};base64,${base64Data}`;
      const downloadLink = document.createElement('a');
      downloadLink.setAttribute('target', '_blank');
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    }

    process(
      api.reqData({ url: `attachments/${id}` }),
      api.get.request,
      api.fullFilled(({ response }) => {
        if (response) {
          let contentType = '';
          if (type === 'pdf') {
            contentType = 'application/pdf';
            downloadBase64File(contentType, response.data.data, file_name);
          } else {
            contentType = `image/${type}`;
            setImage(response.data.data);
          }
        }
      })
    );
  };

  return (
    <div id="reviewer-review-table-expand-content-custom">
      <ul className="info-section">
        {Object.values(content)
          .sort((prev, cur) => prev.field_order - cur.field_order)
          .map((cur) => {
            const name = cur?.field_name;
            const file = cur?.field_type === 'file' ? cur : null;
            const id = file?.id;

            const file_name = file?.file_name;

            return (
              <li key={cur.field_name}>
                <h4 title={name}>{optionalField[name as OptionalCustomField] || name}</h4>
                {cur?.field_type === 'text' && <p>{isExpired ? '만료됨 ' : cur.value}</p>}
                {cur?.field_type === 'file' &&
                  (file_name === '' ? (
                    <p className="disabled">첨부 파일 없음</p>
                  ) : (
                    <>
                      {!file_name?.includes('pdf') && (
                        <Modal
                          content={({ closeHandler }) => {
                            return (
                              <div className={classNames(['reviewer-image-section-modal', { expired: isExpired }])}>
                                <i className="close-btn" onClick={closeHandler} />
                                {isExpired ? (
                                  <i className="image-expired" />
                                ) : (
                                  <img
                                    style={{ width: '100%' }}
                                    src={`data:image/jpeg;base64,${image}`}
                                    alt={`${name}-img`}
                                  />
                                )}
                              </div>
                            );
                          }}
                        >
                          <Button type="button" onClick={() => downloadHandler(id, file_name)}>
                            <p>첨부 사진 보기</p>
                          </Button>
                        </Modal>
                      )}
                      {file_name?.includes('pdf') && (
                        <Button type="button" onClick={() => downloadHandler(id, file_name)}>
                          <p>PDF 다운로드</p>
                        </Button>
                      )}
                    </>
                  ))}
              </li>
            );
          })}
      </ul>

      <article />
    </div>
  );
};

export default Custom;
