import React, { useEffect, useReducer } from 'react';
import { useNavigate } from 'react-router-dom';
import { Validator, Input, Footer } from 'common/components';
import { authActions } from 'store/actions/root';
import { useDispatch } from 'common/hooks/root';
import { Layer } from 'layout';
import produce from 'immer';
import './LoginPage.scss';

type Action =
  | { type: 'loading'; payload: boolean }
  | { type: 'user'; payload: Partial<{ name: string; password: string; error: string }> };

interface State {
  loading: boolean;
  user: { name: string; password: string; error: string };
}

const initialState: State = {
  loading: false,
  user: { name: '', password: '', error: '' },
};

const reducer = (state: State, action: Action) => {
  const { type, payload } = action;
  // prettier-ignore
  return produce(state, (draft) => {
    switch (type) {
      case 'loading': draft['loading'] = payload; break;
      case 'user': draft['user'] = { ...state.user, ...payload }; break;
    }
  });
};

export const LoginPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [state, setState] = useReducer(reducer, initialState);
  const { user } = state;

  const submitHandler = async () => {
    setState({ type: 'loading', payload: true });

    const params = { root_name: user.name, password: user.password };
    const { payload: login } = await dispatch(authActions.login(params));
    // 로그인 에러 발생
    if (login) setState({ type: 'user', payload: { error: login } });
    setState({ type: 'loading', payload: false });
  };

  // 로그인 체크 후 자동 로그인
  useEffect(() => {
    const data = localStorage.getItem('ROOT_TOKEN');
    if (data) {
      const authObj = JSON.parse(data);
      const expire = new Date(authObj.expire).getTime();
      const bumperTime = 1000 * 60 * 60; // 1 hour (왜 1시간을 빼는지 이유는 모르겠음)

      if (expire - bumperTime - Date.now() > 0) navigate('/root/client-management');
      else localStorage.removeItem('ROOT_TOKEN');
    }
  }, []);

  return (
    <>
      <div id="root-login">
        <div id="root-login-form-field">
          <div className="signin-title">
            <i />
          </div>
          <section className="form-section">
            <Validator.Provider form onSubmit={submitHandler}>
              <div className="login-text">Root Login</div>

              <Layer styles={{ marginBottom: 16 }}>
                <Input
                  className="basic"
                  placeholder="아이디"
                  onChange={(e) => setState({ type: 'user', payload: { name: e.target.value, error: '' } })}
                />
              </Layer>

              <Layer styles={{ marginBottom: 16 }}>
                <Input
                  className="basic"
                  type="password"
                  placeholder="비밀번호"
                  onChange={(e) => setState({ type: 'user', payload: { password: e.target.value, error: '' } })}
                />
              </Layer>

              <Validator.Error error={state.user.error} top={40} />

              <Validator.Submit
                text="로그인"
                loading={state.loading}
                disabled={!user.name || !user.password || !!user.error}
                styles={{ width: '100%', height: 60 }}
              />
            </Validator.Provider>
          </section>
        </div>
      </div>
      <Footer />
    </>
  );
};
