import React, { useReducer, useRef, useEffect } from 'react';
import { Slider, Button, Alert } from 'common/components';
import classNames from 'classnames';
import { ClickOutsideEffect, api, process } from 'common/functions';
import { useSelector } from 'common/hooks/admin';
import './Sensitive.scss';
import produce from 'immer';

type Action =
  | { type: 'toggle'; payload: boolean }
  | { type: 'level'; payload: number }
  | { type: 'defaultLevel'; payload: number };
interface State {
  toggle: boolean;
  level: number;
  defaultLevel: number;
}

const initialState = {
  toggle: false,
  level: 1,
  defaultLevel: 1,
};

const reducer = (state: State, action: Action) => {
  return produce(state, (draft) => {
    // prettier-ignore
    switch (action.type) {
      case 'toggle': draft['toggle'] = action.payload; break;
      case 'level': draft['level'] = action.payload; break;
      case 'defaultLevel': draft['defaultLevel'] = action.payload; break;
    }
  });
};

const Sensitive: React.FC<{ loadHandler: () => void }> = ({ loadHandler }) => {
  const { set } = Alert.Context();
  const { my } = useSelector((state) => state.auth);
  const wrapperRef = useRef<HTMLElement>(null);
  const [state, setState] = useReducer(reducer, initialState);

  ClickOutsideEffect({
    target: wrapperRef.current,
    toggle: state.toggle,
    close: () => {
      setState({ type: 'toggle', payload: false });
      setState({ type: 'level', payload: state.defaultLevel });
    },
  });

  const getLevelHandler = () => {
    process(
      api.reqData({ url: 'threshold' }),
      api.get.request,
      api.fullFilled(({ response }) => {
        if (response) {
          const level = response.data.threshold_level;
          setState({ type: 'level', payload: level });
          setState({ type: 'defaultLevel', payload: level });
        }
      })
    );
  };

  const submitHandler = () => {
    process(
      api.reqData({ url: 'threshold', params: { level: state.level, admin_id: my?.id } }),
      api.put.request,
      api.fullFilled(({ response }) => {
        if (response) {
          setState({ type: 'defaultLevel', payload: state.level });
          setState({ type: 'toggle', payload: false });
          loadHandler();
          set({ success: '설정값 변경이 완료되었습니다.' });
        }
      })
    );
  };

  useEffect(() => {
    getLevelHandler();
  }, []);

  return (
    <article ref={wrapperRef} id="setting-page-table-sensitive" className={classNames({ active: state.toggle })}>
      <div className="title">얼굴 유사도 판정 민감도 설정값</div>
      <Slider
        max={5}
        min={1}
        disabled={!state.toggle}
        value={state.level}
        onChange={(l: number) => setState({ type: 'level', payload: l })}
      />
      <Button
        text={state.toggle ? '완료' : '변경'}
        onClick={() => {
          if (!state.toggle) setState({ type: 'toggle', payload: true });
          else submitHandler();
        }}
      />
      <div className="notice">설정값이 높을수록 수동심사 범위가 넓어집니다.</div>
    </article>
  );
};

export default Sensitive;
