import React, { createContext, useContext, useEffect, useReducer } from 'react';
import produce from 'immer';
import { eddCategoryFactory } from '../data/data';

type RaWeightContextType = {
  state: State;
  setState: React.Dispatch<Action>;
  submitHandler: () => void;
};
export const RaWeightContext = createContext<RaWeightContextType | null>(null);
export type Risk = { id: number; name: string; weight: number };

type Action =
  | { type: 'edit'; payload: boolean }
  | { type: 'extraWeight'; payload: null }
  | { type: 'data'; payload: Risk[] }
  | { type: 'defaultData'; payload: Risk[] };
type State = {
  extraWeight: number;
  data: Risk[];
  defaultData: Risk[];
};

const initialState = {
  extraWeight: 1,
  data: [],
  defaultData: [],
};

const reducer = (state: State, action: Action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case 'extraWeight':
        draft['extraWeight'] = draft['data'].reduce((acc, cur) => acc - cur.weight * 10, 10) / 10;
        break;
      case 'data':
        draft['data'] = action.payload;
        break;
      case 'defaultData':
        draft['defaultData'] = action.payload;
        break;
      default:
        break;
    }
  });
};

const [getData, setData] = eddCategoryFactory();

interface Props {
  children: React.ReactNode;
}
function RaWeightProvider({ children }: Props) {
  const [state, setState] = useReducer(reducer, initialState);
  // FIXME: API int 후 제거

  const loadHandler = () => {
    //
    const data = getData();

    setState({ type: 'data', payload: data });
    setState({ type: 'defaultData', payload: data });

    setState({ type: 'extraWeight', payload: null });
  };

  useEffect(() => {
    loadHandler();
  }, []);

  const submitHandler = () => {
    // TODO: PUT RA 가중치
    console.log('RaWeight submitHandler', { data: state.data });
    setData(state.data);
    loadHandler();
  };

  return <RaWeightContext.Provider value={{ state, setState, submitHandler }}>{children}</RaWeightContext.Provider>;
}

RaWeightProvider.Consumer = RaWeightContext.Consumer as React.Consumer<RaWeightContextType>;
export default RaWeightProvider;

export const useRaWeightContext = () => {
  const context = useContext(RaWeightContext);
  if (!context) throw new Error('raWeightContext is null');

  return context;
};
