import React, { useContext, useEffect, useReducer } from 'react';
import { produce } from 'immer';
import { M_Select } from 'common/components';
// import { api, process } from 'common/functions';
import { ContentContext } from 'layout/admin/content/Content';
import Promotion from '../promotion/Promotion';
import Forbidden from '../forbidden/Forbidden';
import CtCriterionContent from './CtCriterionContent';
import './CriterionContent.scss';

type Data = {
  module: {
    custom_field: boolean;
    id_card_ocr: boolean;
    id_card_verification: boolean;
    face_authentication: boolean;
    account_verification: boolean;
    liveness: boolean;
  };
  configs: { allow_custom_field: boolean; use_ra: boolean };
  template: M_Select.item;
};
export type State = {
  edit: boolean;
  data: Data;
  defaultData: Data;
};

export type Action =
  | { type: 'setEdit'; payload: boolean }
  | { type: 'setData'; payload: Partial<Data> }
  | { type: 'setDefaultData'; payload: Partial<Data> };

const initialData = {
  module: {
    custom_field: false,
    id_card_ocr: false,
    id_card_verification: false,
    face_authentication: false,
    account_verification: false,
    liveness: false,
  },
  configs: { allow_custom_field: false, use_ra: false },
  template: { title: '기본 템플릿 설정', value: 'default' },
};
const initialState: State = {
  edit: false,
  data: { ...initialData },
  defaultData: { ...initialData },
};

const reducer = (state: State, action: Action) => {
  return produce(state, (draft) => {
    // prettier-ignore
    switch (action.type) {
      case 'setEdit': draft['edit'] = action.payload; break;
      case 'setData': draft['data'] = { ...draft.data, ...action.payload }; break;
      case 'setDefaultData': draft['defaultData'] = { ...draft.defaultData, ...action.payload }; break;
    }
  });
};

// TODO: API int
// const get = (url: string) => {
//   return process(
//     api.reqData({ url }),
//     api.get.request,
//     api.fullFilled(({ response }) => {
//       if (response?.data.list) return response?.data.list;
//       if (response?.data.module) return response?.data.module;
//       return undefined;
//     })
//   );
// };

// const put = (url: string, data: { use_ra: string } | { customer_id: number; module: { custom_field: boolean } }) => {
//   return process(api.reqData({ url, data }), api.put.request);
// };
type ResponseConfig = { key: keyof Data['configs']; value: boolean };
const parseConfigs = (list: ResponseConfig[]) => {
  return list.reduce((acc, cur) => {
    if (cur.key === 'allow_custom_field' || cur.key === 'use_ra') {
      return { ...acc, [cur.key]: cur.value };
    }
    return acc;
  }, initialData.configs);
};
const testConfigs = (a: boolean, b: boolean): ResponseConfig[] => [
  { key: 'allow_custom_field', value: a },
  { key: 'use_ra', value: b },
];
const testModules = (a: boolean): Data['module'] => ({ ...initialData.module, custom_field: a });

function CriterionContent() {
  const { setLoading } = useContext(ContentContext);
  // const customer_info = useSelector((state) => state.auth.data);
  const [state, setState] = useReducer(reducer, initialState);
  const { configs } = state.defaultData;
  const { allow_custom_field, use_ra } = configs;
  // const { custom_field } = module;

  const loadHandler = async () => {
    setLoading(true);

    // const [list, module] = await Promise.all([get('ra_threshold'), get('modules')]);
    const [list, module] = await Promise.all([testConfigs(true, true), testModules(true)]);
    const configs = parseConfigs(list);
    const template = { title: '기본 템플릿 설정', value: 'default' };

    setState({ type: 'setData', payload: { configs, module, template } });
    setState({ type: 'setDefaultData', payload: { configs, module, template } });

    setLoading(false);
  };

  useEffect(() => {
    loadHandler();
  }, []);

  // const handleEddRaConfigSubmit = async () => {
  //   setLoading(true);
  //   setState({ type: 'setEdit', payload: false });
  //   // TODO: 현재는 use_ra 없어서 put 오류발생함
  //   // await Promise.all([
  //   //   // put('configs', { use_ra: String(use_ra) }),
  //   //   customer_info?.customer_id &&
  //   //     put('modules/admin', {
  //   //       customer_id: customer_info?.customer_id,
  //   //       module: { ...state.defaultData.module, custom_field },
  //   //     }),
  //   // ]);
  //   // loadHandler();

  //   setState({ type: 'setDefaultData', payload: state.data });
  //   setLoading(false);
  // };

  // const eddRaConfigisDirty =
  //   state.data.module.custom_field !== state.defaultData.module.custom_field ||
  //   state.data.configs.use_ra !== state.defaultData.configs.use_ra;

  return (
    <div id="admin-manage-edd-criterion-content">
      {allow_custom_field ? (
        <>
          {use_ra ? (
            <CtCriterionContent />
          ) : (
            <Forbidden
              content={
                <>
                  <p>상단의 EDD 및 RA 기능을 활성화 하신 후</p>
                  <p>RA 평가기준을 보실 수 있습니다.</p>
                </>
              }
            />
          )}
        </>
      ) : (
        <Promotion />
      )}
    </div>
  );
}

export default CriterionContent;
