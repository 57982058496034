import { Data } from './customerRisk/CustomerRisk';

export type FlatTree = { names: string[]; score: number };
type TreeNode = { name: string; score: number; tree: TreeNode[] };

export const treeToFlatTree = (trees: TreeNode[], parentName = '') => {
  const flatten: FlatTree[] = [];

  const dfs = (trees: TreeNode[], parentName: string[]) => {
    for (const tree of trees) {
      if (tree.tree.length) {
        dfs(tree.tree, [...parentName, tree.name]);
      } else {
        flatten.push({ names: [...parentName, tree.name], score: tree.score });
      }
    }
  };

  dfs(trees, parentName ? [parentName] : []);

  return flatten;
};

export const flatTreeToTree = (flatTree: FlatTree[]) => {
  const initialTree: TreeNode[] = [];
  const addNode = (node: TreeNode, names: string[]): TreeNode => {
    if (names.length === 0) {
      return node;
    }
    const [name, ...rest] = names;
    const childNode = node.tree.find((n) => n.name === name);
    if (childNode) {
      return addNode(childNode, rest);
    }
    const newNode = { name, score: 0, tree: [] };
    node.tree.push(newNode);
    return addNode(newNode, rest);
  };

  const createTree = (initialTree: TreeNode[], flatTree: FlatTree[]) => {
    return flatTree.reduce((tree, item) => {
      const names = item.names.slice();
      const lastName = names.pop() || '';
      const parentNode = addNode({ name: '', score: 0, tree }, names);
      parentNode.tree.push({ name: lastName, score: item.score, tree: [] });
      return tree;
    }, initialTree);
  };

  const tree = createTree(initialTree, flatTree);

  return tree;
};

export const checkActiveRaScore = (data: Data[]) => {
  return data
    .filter((el) => el.active)
    .every((el) => {
      const flatTree = treeToFlatTree(el.tree);
      return flatTree.every((ft) => ft.score <= el.score);
    });
};

export const addRaData: (len: number, category_id: number) => Data = (len, category_id) => ({
  id: 99 + len,
  name: '',
  type: 'tree',
  active: true,
  isAppend: true,
  edd_category_id: category_id,
  score: 0,
  deletable: true,
  mutable: true,
  order: 999,
  tree: [],
});
