import { Radio, Popover, Button } from 'common/components';
import React, { Dispatch, useState } from 'react';
import { api } from 'common/functions';
import { Action, State } from '../CtSubscribeSetting';
import './Message.scss';

interface Props {
  state: State;
  setState: Dispatch<Action>;
  loadHandler: () => void;
}

const Message: React.FC<Props> = ({ state, setState, loadHandler }) => {
  const { use_custom_end_message, custom_end_message, email_notification, sms_notification } = state.vas;

  const [edit, setEdit] = useState<boolean>(false);
  const isDirty =
    use_custom_end_message !== state.defaultVas.use_custom_end_message ||
    custom_end_message !== state.defaultVas.custom_end_message;

  const updateCustomEndMessage = async () => {
    const data = {
      use_custom_end_message: use_custom_end_message.toString(),
      custom_end_message,
    };
    await api.put.request(api.reqData({ url: 'configs', data }));
    setState({ type: 'put' });
    setEdit(false);
    loadHandler();
  };

  const defaultEndMessage = () => {
    if (email_notification && sms_notification) return '이메일, SMS를 통해 승인 여부를';
    if (email_notification) return '이메일을 통해 승인 여부를';
    if (sms_notification) return 'SMS를 통해 승인 여부를';
    return '다시';
  };

  return (
    <section id="subscribe-management-setting-message">
      <div className="header">
        <div className="title">인증완료 메시지 설정</div>
        <Popover
          position="right center"
          clickOutside
          content={() => {
            return (
              <div id="admin-subscribe-message-popover-content" className="description">
                해당 문구는 고객이 인증 단계를 완료한 후의 최종 화면에서 보여지며, 작성한 모습대로 표시됩니다.
              </div>
            );
          }}
        >
          <i className="title-info" />
        </Popover>
      </div>
      <div className="content">
        <div className="radio">
          <Radio.Group
            value={use_custom_end_message}
            disabled={!edit}
            onChange={(value) => {
              setState({ type: 'useCustom', payload: value });
              if (value) {
                const textarea = document.querySelector<HTMLTextAreaElement>('.input-container');
                if (textarea) textarea.focus();
              }
            }}
          >
            <Radio.Item text="기본" value={false} />
            <Radio.Item text="사용자 설정" value />
          </Radio.Group>
        </div>
        <div className="textarea">
          <div>
            <p
              onClick={() => {
                if (edit) setState({ type: 'useCustom', payload: false });
              }}
            >
              입력하신 KYC 인증 정보 최종 검토 후 {defaultEndMessage()} 안내드리겠습니다.
            </p>
          </div>
          <div>
            {(edit || use_custom_end_message) && (
              <>
                <textarea
                  className="input-container"
                  value={custom_end_message}
                  maxLength={85}
                  placeholder="인증 완료 후 표시할 문구를 입력해 주세요."
                  disabled={!edit}
                  onClick={() => setState({ type: 'useCustom', payload: true })}
                  onChange={(e) => {
                    const {
                      target: { value },
                    } = e;
                    const text = value.slice(0, 85);
                    setState({ type: 'message', payload: text });
                  }}
                  onKeyUp={() => {
                    const textarea = document.querySelector<HTMLElement>('.input-container');

                    if (textarea) {
                      textarea.style.height = 'auto';
                      const height = textarea.scrollHeight;
                      textarea.style.height = `${height + 2}px`;
                    }
                  }}
                />
                <span className="input-length">{custom_end_message.length}/85</span>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="button-group">
        {edit ? (
          <>
            <Button
              className="cancel"
              text="취소"
              onClick={() => {
                setState({ type: 'vas', payload: state.defaultVas });
                setEdit(false);
              }}
            />
            <Button
              className="submit"
              text="완료"
              disabled={!isDirty || !state.vas.custom_end_message}
              onClick={updateCustomEndMessage}
            />
          </>
        ) : (
          <Button className="submit" text="편집" onClick={() => setEdit(true)} />
        )}
      </div>
    </section>
  );
};

export default Message;
